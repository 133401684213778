import React from 'react';

// Mock data
const articlesData = [
    {id: 1, title: 'Colleen Hoover: A Symphony of Emotions', author: 'Lucius Small', date: '2023-06-01', link: '/colleenhoover'},
    {id: 2, title: 'Colleen Hoover Books: The good, the bad, the ugly', author: 'Lucius Small', date: '2023-05-25', link: '/colleenhooverbooks'},
    {id: 3, title: "The Emotional Journey Continues: A Look at Colleen Hoover's 'It Starts with Us'", author: 'Lucius Small', date: '2023-06-30', link: '/itstartswithus'},
    {id: 4, title: "A Deep Dive into Colleen Hoover's Classic 'It Ends with Us'", author: 'Lucius Small', date: '2023-06-30', link: '/itendswithus'},
    {id: 5, title: "It Ends with us Summary - A quick but detailed plot walkthrough'", author: 'Lucius Small', date: '2023-06-30', link: '/itendswithussummary'},
    {id: 6, title: "It Ends with us pdf - A guide on how to obtain an it ends with us pdf", author: 'Lucius Small', date: '2023-06-30', link: '/itendswithuspdf'},
    {id: 7, title: "Verity Summary - A chilling journey into the dark corners of a writer's life", author: 'Lucius Small', date: '2023-06-30', link: '/veritysummary'},
    {id: 8, title: "Colleen Hoover Books in Order: A Journey Through Her Literary World", author: 'Lucius Small', date: '2023-06-30', link: '/colleenhooverbooksinorder'},
    {id: 9, title: "Jack Carr Books in Order: A Thrilling Journey through his Novels", author: 'Gavin Babji', date: '2023-07-25', link: '/jackcarrbooksinorder'},
    {id: 10, title: "Harry Potter Books in Order: A Magical Journey", author: 'Gavin Babji', date: '2023-07-04', link: '/harrypotterbooksinorder'},
    {id: 11, title: "Louise Penny Books in Order: An Enchanting Mystery Series", author: 'Gavin Babji', date: '2023-07-30', link: '/louisepennybooksinorder'},
    {id: 12, title: "Jack Carr - Realism in Thrillers", author: 'Lucius Small', date: '2023-07-31', link: '/jackcarr'},
    {id: 13, title: "Jack Carr Books: The action-packed world of a Navy SEAL", author: 'Lucius Small', date: '2023-07-31', link: '/jackcarrbooks'},
    {id: 14, title: "Bridgerton Books", author: 'Gavin Babji', date: '2023-07-31', link: '/bridgertonbooks'},
    {id: 15, title: "Bridgerton Books in Order: An Enchanting Journey", author: 'Gavin Babji', date: '2023-07-31', link: '/bridgertonbooksinorder'},
    {id: 16, title: "Verity - A thrilling exploration of love, truth, and the power of storytelling", author: 'Lucius Small', date: '2023-07-31', link: '/veritybook'},
    {id: 17, title: "Verity - A compelling dive into the human psyche by Colleen Hoover", author: 'Lucius Small', date: '2023-07-31', link: '/veritycolleenhoover'},






    // More articles...
];

const Articles = () => {
    return (
        <div className="container">
            <h1 className="my-3">Articles</h1>
            <ul className="list-group">
                {articlesData.map((article) => (
                    <li className="list-group-item" key={article.id}>
                        <h2><a href={article.link}>{article.title}</a></h2>
                        <p>{article.author} - {new Date(article.date).toDateString()}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Articles;
