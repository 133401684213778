import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import TheTerminalList from './images/the-terminal-list.jpg';
import SavageSon from './images/Savage_son_cover-400x604.jpg';
import TrueBeliever from './images/true-believer.jpg';
import TheDevilsHand from './images/the-devils-hand-book-cover-400x608.jpg';
import InTheBlood from './images/In-The-Blood-by-Jack-Carr-400x607.jpg';
import OnlyTheDead from './images/OnlyTheDeadHC_final-1-400x604.jpg';

import JackCarrBooks from './images/Jack-Carr.jpg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const JackCarrBooksInOrder = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Logo size="width-150"/>
    <Helmet>
      <title>Jack Carr Books in Order</title>
      <meta name="description" content="List of Jack Carr Books in the order they were released" />
    </Helmet>
    <div>
      <h1 className="article-title">Jack Carr Books in Order: A Thrilling Journey through his Novels</h1>
      <small>By Lucius Small</small>
    </div>
    <img style={{paddingTop: '15px'}} className="image width-750" src={JackCarrBooks} alt="Jack Carr Books" />

    <section style={{textAlign: 'center'}} className="section">
      <h2 className="sub-title">Jack Carr's Chronological Bibliography</h2>
      <p>
        Discover the evolution of Jack Carr's thrilling storytelling. Here's a list of his books in the order they were released:
      </p>

      <ol style={{justifyItems: 'center'}}>
        <li><strong>"The Terminal List"</strong>(2018)
        </li>
        <img style={{paddingTop: '15px', paddingBottom: '30px', width: '40%'}} className="image width-150" src={TheTerminalList} alt="The terminal list - Jack Carr book 1" />

        <h2>Search for the cheapest place to buy Jack Carr books</h2>
        <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

        <li><strong>"True Believer"</strong> (2019)
        </li>
        <img style={{paddingTop: '15px', paddingBottom: '30px', width: '40%'}} className="image width-150" src={TrueBeliever} alt="True believer - Jack Carr book 2" />

        <li><strong>"Savage Son"</strong> (2020)
        </li>
        <img style={{paddingTop: '15px', paddingBottom: '30px', width: '40%'}} className="image width-150" src={SavageSon} alt="Savage son - Jack Carr book 3" />

        <li><strong>"The Devil's Hand"</strong>(2021)
        </li>
        <img style={{paddingTop: '15px', paddingBottom: '30px', width: '40%'}} className="image width-150" src={TheDevilsHand} alt="The Devil's Hand - Jack Carr book 4" />

        <li><strong>"In The Blood"</strong> (2022)
        </li>
        <img style={{paddingTop: '15px', paddingBottom: '30px', width: '40%'}} className="image width-150" src={InTheBlood} alt="In the Blood - Jack Carr Book 5" />

        <li><strong>"Only the Dead"</strong> (2023)
        </li>
        <img style={{paddingTop: '15px', paddingBottom: '30px', width: '40%'}} className="image width-150" src={OnlyTheDead} alt="Only The dead - Jack Carr Book 6" />

      </ol>
    </section>

    <h2>Search for the cheapest place to buy Jack Carr books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>


    <section>
    <h2 className="sub-title">Jack Carr books in his recommended order</h2>
    <p>
      Jack Carr's books follow the thrilling adventures of protagonist James Reece and are best enjoyed when read in order. Check out some of our other articles for more information on Jack Carr and his books.
    </p>
    </section>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
      <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
    </section>
    <Footer />
  </div>
);

export default JackCarrBooksInOrder;
