import React from 'react';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';


const AmazonLinks = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => 
   (
    <div className="article">
    <Logo size="width-150"/>
    <Helmet>
      <title>Amazon Links</title>
      <meta name="description" content="Links to Amazon Products" />
    </Helmet>
        <div>
          <h1 className="article-title">Amazon Links</h1>
          <small>By Gavin Babji</small>



          <a target="_blank" href="https://amzn.to/47I02CW"><p>Cetaphil moisturiser</p></a>

          <a target="_blank" href="https://amzn.to/47oBQG5"><p>Cetaphil cleanser</p></a>

          <a target="_blank" href="https://amzn.to/3w3F0B2"><p>Million dollar weekend</p></a>

          <a target="_blank" href="https://amzn.to/451jsRk"><p>Protein Powder</p></a>


          <a target="_blank" href="https://amzn.to/3OXdDQh"><p>Toor Dal 1kg</p></a>


          <a target="_blank" href="https://amzn.to/3Qy1kLo"><p>Parker Quink Pen refills</p></a>




            <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

            <section>
              <h2 className="sub-title">See more like this</h2>
              <div className="flex">
              <div className="flex-item">
                <a href="/colleenhoover">
                    <span>Colleen Hoover</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/colleenhooverbooks">
                    <span>Colleen Hoover Books</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/itstartswithus">
                    <span>It Starts With Us</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/itendswithus">
                    <span>It Ends With Us</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/itendswithussummary">
                    <span>It Ends With Us Summary</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/itendswithuspdf">
                    <span>It Ends With Us PDF</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/veritysummary">
                    <span>Verity Summary</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/colleenhooverbooksinorder">
                    <span>Colleen Hoover Books in Order</span>
                </a>
                </div>
                <div className="flex-item">
                  <a href="/jackcarrbooksinorder">
                    <span>Jack Carr Books In Order</span>
                  </a>
                </div>
                <div className="flex-item">
                    <a href="/jackcarrbooks">
                        <span>Jack Carr Books</span>
                    </a>
                </div>
                <div className="flex-item">
                    <a href="/jackcarr">
                        <span>Jack Carr</span>
                    </a>
                </div>
                <div className="flex-item">
                    <a href="/louisepennybooksinorder">
                        <span>Louise Penny Books In Order</span>
                    </a>
                </div>
              </div>
            </section>

            <Footer />  
        </div>
      </div>
    );


export default AmazonLinks;
