import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import ItEndsWithUsBlakeLively from './images/it-ends-with-us-blake-lively.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const ItEndsWithUsPdf = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Helmet>
      <title>It Ends With Us pdf</title>
      <meta name="description" content="How to get a pdf for It Ends With Us" />
    </Helmet>
    <Logo size="width-150" />
    <div>
      <h1 className="page-title">It Ends with us pdf - A guide on how to obtain an it ends with us pdf</h1>
      <small>By Lucius Small</small>
    </div>

    <img style={{paddingTop: '15px'}} className="image width-750" src={ItEndsWithUsBlakeLively} alt="Blake Lively - It Ends With Us" />

    <section className="section">
      <h2 className="sub-title">An Intimate Voyage in Literature</h2>
      <p>Delving into the raw emotions of love, resilience, and liberation, Colleen Hoover’s "It Ends with Us" is a contemporary romance novel that strikes a chord with readers across the globe. The intricate tapestry of relationships, the deep-seated fears, and the bold break from the chains of abuse make "It Ends with Us" an unforgettable journey.</p>
      
      <h2 style={{paddingTop: '25px'}}className="sub-title"> "It Ends with Us" - The Unfolding Drama</h2>
      <p>"It Ends with Us" takes us on an emotional journey with Lily Bloom, a strong-willed woman who sets out to realize her dream of owning a floral shop in Boston, all while grappling with the shadows of her troubled past. Encountering love and heartbreak, Lily navigates the complexities of relationships, the realities of abuse, and the courage it takes to break free. The title, "It Ends with Us," signifies Lily's determination to end the cycle of abuse in her family, resonating deeply with readers who seek a story of hope and liberation.</p>
      
      <h2 style={{paddingTop: '25px'}}className="sub-title">Finding "It Ends with Us PDF"</h2>
      
      <p>For those eager to immerse themselves in this captivating narrative, an "It Ends with Us PDF" ebook offers a convenient way to dive into Lily's world. Ebooks have made it easy to access and enjoy your favorite titles anywhere, anytime, and the "It Ends with Us PDF" is no exception.</p>
    </section>

    <h2>Search for the cheapest place to buy It Ends With Us</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section className="section">

      <h2 className="sub-title">Purchasing Online Ebooks - The Convenient Way</h2>
      <p>The most reliable and legal way to secure an "It Ends with Us PDF" is to purchase an online ebook. An online ebook not only provides immediate access to the title but also contributes to the author's royalties, supporting their work.</p>    
      
      <h2 style={{paddingTop: '25px'}}className="sub-title">Pages on Pages - Your One-Stop-Shop for Affordable Ebooks and Physical Books</h2>
      <p>To find the most affordable options for the "It Ends with Us PDF" ebook, look no further than the website you are currently browsing - Pages on Pages. Known for its vast collection of titles, Pages on Pages offers competitive prices for both online ebooks and physical books, ensuring you can enjoy "It Ends with Us" and other captivating titles without straining your budget.</p>
      <h2 style={{paddingTop: '25px'}}className="sub-title">Ready to Dive In? Search Here!</h2>
      <p>If you're ready to explore the world of "It Ends with Us," simply use the search bar below to find your copy of the "It Ends with Us PDF." Enter the title and let Pages on Pages do the rest, guiding you towards your next unforgettable literary journey.</p>
    </section>
 

    <h2>Search for the cheapest place to buy It Ends With Us</h2>

    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
        <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
    </section>

    <Footer />
  </div>
);

export default ItEndsWithUsPdf;
