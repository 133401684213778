import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import ColleenHooverBooksOrdered from './images/colleen-hoover-books-in-order.png';
import ColleenHooverBooks from './images/colleen-hoover-books.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const ColleenHooverBooksInOrder = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Logo size="width-150"/>
    <Helmet>
      <title>Colleen Hoover Books in Order</title>
      <meta name="description" content="List of Colleen Hoover Books in the order they were released" />
    </Helmet>
    <div>
      <h1 className="article-title">Colleen Hoover Books in Order: A Journey Through Her Literary World</h1>
      <small>By Lucius Small</small>
    </div>
    <img style={{paddingTop: '15px'}} className="image width-750" src={ColleenHooverBooks} alt="Colleen Hoover Books" />

    <section className="section">
      <h2 className="sub-title">Colleen Hoover's Chronological Bibliography</h2>
      <p>
        Experience Colleen Hoover's poignant storytelling as it evolved over the years. Here's a list of her books in the order they were released:
      </p>

      <ol>
        <li>"Slammed" (2012)</li>
        <li>"Point of Retreat" (2012)</li>
        <li>"Hopeless" (2012)</li>
        <li>"This Girl" (2013)</li>
        <li>"Losing Hope" (2013)</li>
        <li>"Finding Cinderella" (2013)</li>
        <li>"Maybe Someday" (2014)</li>
        <li>"Maybe Not" (2014)</li>
        <li>"Ugly Love" (2014)</li>
        <li>"Confess" (2015)</li>
        <li>"November 9" (2015)</li>
        <li>"It Ends with Us" (2016)</li>
        <li>"Without Merit" (2017)</li>
        <li>"All Your Perfects" (2018)</li>
        <li>"Verity" (2018)</li>
        <li>"Regretting You" (2019)</li>
        <li>"Heart Bones" (2020)</li>
        <li>"Layla" (2020)</li>
        <li>"It Starts with Us" (2022)</li>
        <li>"Reminders of Him" (2022)</li>
        <li>"Too Late: Definitive Edition" (2023)</li>
      </ol>

      <p>Collaborative Works (with release dates):</p>

      <ol>
        <li>"Never Never: Part One" (2015, with Tarryn Fisher)</li>
        <li>"Never Never: Part Two" (2015, with Tarryn Fisher)</li>
        <li>"Never Never: Part Three" (2016, with Tarryn Fisher)</li>
        <li>"Maybe Now" (2018)</li>
      </ol>
    </section>

    <h2>Search for the cheapest place to buy Colleen Hoover books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <img style={{paddingTop: '15px'}} className="image width-750" src={ColleenHooverBooksOrdered} alt="Colleen Hoover Books" />

    <section>
    <h2 className="sub-title">Colleen Hoover books in her recommended order</h2>
    <p>
      Most of Colleen Hoover's books can be read in any order as they are standalone. However the graphic above this depicts the books that exist in a series and the order in which those books should be read. Look closely for all of Colleen Hoover's books in order.
    </p>

    </section>

    <h2>Search for the cheapest place to buy Colleen Hoover books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
        <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>

      
    </section>
    <Footer />
  </div>
);

export default ColleenHooverBooksInOrder;
