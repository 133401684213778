import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import ColleenHooverImage from './images/colleen-hoover-image.jpeg';
import ColleenHooverBooksShelf from './images/colleen-hoover-books-shelf.png';
import ColleenHooverHoldingBook from './images/colleen-hoover-holding-book.jpeg';
import ColleenHooverBooks from './images/colleen-hoover-books.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const ColleenHooverPage = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Helmet>
      <title>Colleen Hoover</title>
      <meta name="description" content="From the heart of Texas emerged a powerfully poignant voice in contemporary literature - Colleen Hoover. A #1 New York Times bestselling author, Hoover has crafted numerous unforgettable stories that blend raw emotion, compelling romance, and lifelike characters." />
    </Helmet>
    <Logo size="width-150" />
    <div>
      <h1 className="page-title">Colleen Hoover - A Symphony of Emotions</h1>
      <small>By Lucius Small</small>
    </div>

    <img style={{paddingTop: '15px'}} className="image width-750" src={ColleenHooverBooks} alt="Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">The Journey Begins</h2>
      <p>
        Colleen Hoover, born December 11, 1979, in Saltillo, Texas, always had an affinity for literature. Still, she spent several years working in social work and education before diving headfirst into the world of storytelling.
      </p>

      <p>
        In 2012, Hoover self-published her debut novel, "Slammed," an emotionally stirring narrative that quickly found its place on the New York Times Best Seller list. The compelling story about a young woman falling for a passionate slam poet marked the beginning of Hoover's indelible mark on contemporary literature.
      </p>
      <h2 style={{paddingTop: '25px'}}className="sub-title">A Powerful Storyteller</h2>
      <p>
        Hoover didn't stop there. Her subsequent novels further cemented her standing in the world of romance and young adult fiction. "Point of Retreat," the sequel to "Slammed," and "Hopeless," a powerful narrative about life's traumas, showcased her capacity to handle sensitive issues with grace and finesse.
      </p>
    </section>

    <h2>Search for the cheapest place to buy Colleen Hoover books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section className="section">
      <h2 className="sub-title">"It Ends with Us": A Gamechanger</h2>
      <p>
        Arguably one of Hoover's most famous works, "It Ends with Us," breaks away from the pack. This novel offers an unflinching look at a volatile relationship plagued by deep love, passion, and destructive tendencies, a stark departure from standard romance novels.
      </p>
      <p>
        Hoover's characters, often battling personal demons, are deeply human and real. Her talent lies in crafting emotionally rich narratives, creating a roller-coaster of emotions that keeps readers hooked from the first page to the last.
      </p>
      <h2 style={{paddingTop: '25px'}} className="sub-title">Recognizing Excellence: Hoover's Accolades</h2>
      <p>
        Hoover's outstanding contributions to literature haven't gone unnoticed. She has won the Goodreads Choice Award for Best Romance three times—for "Confess," "It Ends with Us," and "Without Merit." Each of these novels resonated with readers worldwide due to their powerful depictions of love, loss, and resilience.
      </p>
    </section>
    <img className="image width-200" src={ColleenHooverHoldingBook} alt="Author Colleen Hoover" />


    <section className="section">
      <h2 className="sub-title">The Bookworm Box: Giving Back to the Community</h2>
      <p>
        Beyond writing, Hoover has a passion for uplifting other authors and supporting various charities. She founded The Bookworm Box, a monthly subscription service that offers signed novels from different authors. All profits from this venture are donated to various charities each month, a testament to Hoover's generosity and commitment to her community.
      </p>
      <h2 style={{paddingTop: '25px'}} className="sub-title">Connecting with Readers</h2>
      <p>
        Hoover is known for her remarkable connection with her readers. She frequently interacts with her fans on social media, often sharing insights about her writing process, upcoming works, and personal life. This genuine, down-to-earth interaction, coupled with her powerful storytelling, has garnered her a loyal fanbase.
      </p>
    </section>

    <img className="image width-150" src={ColleenHooverBooksShelf} alt="Author Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">Hoover's Legacy</h2>
      <p>
        Hoover remains grounded despite her monumental success. She continues to live in Texas with her family, crafting stories that stir the heart and provoke the mind. Her journey reflects her tenacity, talent, and her innate ability to connect with readers on a profound level.
      </p>
      <p>
        In conclusion, Colleen Hoover's blend of poignant storytelling and philanthropic efforts unequivocally establishes her as a remarkable figure in the literary landscape, touching readers' hearts one book at a time.
      </p>
    </section>

    <img className="image width-150" src={ColleenHooverImage} alt="Author Colleen Hoover" />

    <h2>Search for the cheapest place to buy Colleen Hoover books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
        <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
    </section>

    <Footer />
  </div>
);

export default ColleenHooverPage;
