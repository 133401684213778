import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Redirect() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirectUrl = params.get('url');
    const linkCode = params.get('linkCode');
    const tag = params.get('tag');

    if (redirectUrl && isValidUrl(redirectUrl)) {
      window.location.href = redirectUrl + `&linkCode=${linkCode}&tag=${tag}`; // this redirects the user to the specified URL
    } else {
      // handle invalid or missing URL query parameter or navigate to a default page
      window.location.href = '/';
    }
  }, [location]);

  // Check if the URL's domain is in the valid list
  function isValidUrl(url) {
    try {
      const validBaseDomains = [
        'amazon.co'
      ];
  
      const domain = new URL(url).hostname;
  
      // Check if any of the valid base domains is a suffix of the provided domain.
      return validBaseDomains.some(baseDomain => domain.includes(baseDomain));
    } catch (error) {
      return false;  // if there's an error parsing the URL, consider it invalid
    }
  }
  

  return (
    <div>Redirecting...</div>
  );
}

export default Redirect;
