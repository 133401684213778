import React from 'react'
import Logo from '../components/Logo';
import Footer from '../components/Footer';

function PrivacyPolicy() {
  return (
    <>
      <Logo size={'width-150'} />
      <div style={{textAlign: 'left', padding: 20}}>
        <h1>Privacy Policy for Pages on Pages</h1>
        <p>Last Updated: June 20, 2023</p>
        <p>This privacy policy ("Policy") explains how personal information is collected, used, and disclosed by your use of the Pages on Pages website and its accompanying Chrome extension, collectively referred to as the "Services," provided by Singhal Services ("We," "Us," or "Our").</p>
        <p>By using our Services, you agree to the collection, use, and disclosure of your personal information as described in this Policy. If you do not, please do not use the Services.</p>

        <h2>1. Information We Collect</h2>

        <h3>1.1 Information You Provide to Us</h3>
        <p>We may collect information you provide directly to us, such as when you participate in any interactive features of the Services, fill out a form, request customer support, provide any contact or identifying information, or otherwise communicate with us. This information may include, but is not limited to, your email and name when you sign up for our newsletter.</p>

        <h3>1.2 Information We Collect Automatically</h3>
        <p>When you use our Services, we may automatically collect information about you. This includes your IP address, browser type, access times, pages viewed, the links you click on, your search queries, and other standard log information.</p>

        <h2>2. Use of Information</h2>
        <p>We may use the information collected about you for various purposes, such as to:</p>
        <ul>
            <li>Provide, maintain, and improve our Services;</li>
            <li>Respond to your comments, questions, and requests and provide customer service;</li>
            <li>Communicate with you about news and updates;</li>
            <li>Monitor and analyze trends, usage, and activities in connection with our Services;</li>
            <li>Personalize your online experience with us.</li>
        </ul>

        <h2>3. Sharing of Information</h2>
        <p>We may share information about you as follows or as otherwise described in this Privacy Policy:</p>
        <ul>
            <li>When you click on an affiliate link, we may share information with the corresponding affiliate to ensure proper accounting of affiliate transactions;</li>
            <li>We may share your information with third party vendors, consultants, and other service providers who need access to such information to carry out work on our behalf;</li>
            <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process;</li>
            <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of Singhal Services or others.</li>
        </ul>

        <h2>4. Data Storage and Security</h2>
        <p>We take reasonable measures to help protect your personal information in an effort to prevent loss, misuse, unauthorized access, disclosure, alteration, and destruction.</p>

        <h2>5. Changes to this Policy</h2>
        <p>We may revise this Policy from time to time. The most current version of the policy will govern our use of your information and will always be on the website. We will notify you of any changes by posting the new privacy policy on our website.</p>

        <h2>6. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at creasyspines@gmail.com .</p>
      </div>
      <Footer />
    </>  
  )
}

export default PrivacyPolicy;