import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import VerityBookCover from './images/verity-book-sleeves.jpeg';
import ColleenHooverBooks from './images/colleen-hoover-books.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const VerityBook = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Helmet>
      <title>Verity book</title>
      <meta name="description" content="In-depth review of Verity by Colleen Hoover" />
    </Helmet>
    <Logo size="width-150" />
    <div>
      <h1 className="page-title">Verity - A thrilling exploration of love, truth, and the power of storytelling</h1>
      <small>By Lucius Small</small>
    </div>

    <img style={{paddingTop: '15px'}} className="image width-750" src={VerityBookCover} alt="Verity Book Cover - Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">A Novel That Keeps You Hooked</h2>
      <p>“Verity” is an enthralling romantic thriller that unveils the complicated lives of its characters, layer by layer. It's a narrative that holds you captive from the first page to the last, leaving you pondering the fine line between truth and deception, love and obsession.</p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">Colleen Hoover at Her Best</h2>
      <p>Known for her ability to combine romance and suspense, Colleen Hoover outdoes herself in “Verity.” The story revolves around Lowen Ashleigh, a struggling writer who lands an unexpected job to complete the unfinished series of the acclaimed author Verity Crawford. What unfolds is a gripping tale filled with dark secrets, emotional turbulence, and unexpected revelations.</p>

    </section>

    <h2>Search for the cheapest place to buy Verity</h2>
    <SearchBar noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle} noButtons={true}/>

    <section className="section">
      <h2 className="sub-title">The Intricacies of Love and Truth</h2>
      <p>“Verity” paints an intricate picture of love, fear, and the complexities of human psychology. It explores the unending struggle between love and truth, leading readers on a riveting journey that keeps them guessing till the end.</p>

      <p>With “Verity,” Colleen Hoover delivers another masterpiece, proving why she is one of the most loved authors in the romantic thriller genre.</p>

    </section>
    <img className="image width-150" src={ColleenHooverBooks} alt="Verity book - Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">A Must-Read for Thriller Enthusiasts</h2>
      <p>“Verity” is a must-read for anyone who appreciates well-crafted narratives with a thrilling edge. The novel’s well-paced storyline, engaging characters, and startling revelations make it a captivating read from start to finish.</p>

      <p>If this review piqued your interest in "Verity," consider buying the book or exploring other novels by Colleen Hoover. Use the search bar below to find the best deals.</p>

    </section>

    <h2>Search for the cheapest place to buy Verity</h2>

    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>
    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
      <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
    </section>
    <Footer />
  </div>
);

export default VerityBook;
