import React, { useState } from "react";
import styles from '../../css/theme.css'
import {AiOutlineClose} from 'react-icons/ai'
import { postEmail } from "../../api";
import { 
  LinkedinShareButton,
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  TumblrShareButton,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon
} from "react-share";

const Modal = ({ setSubscribed, isCardClicked, setCardClicked }) => {

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);

  const handleSubmit = (e) => {
    postEmail(name, email)
    setSubscribed(true)
    setCardClicked(false)
  }
  return (

    <>
          <div className="modal-content">
            <div className="modal-header modal-header-borderless justify-content-end">
              <button type="button" className="close" data-dismiss="modal" onClick={() => setCardClicked(false)} aria-label="Close">
                <AiOutlineClose />
              </button>
            </div>
            <div className="modal-body d-flex justify-content-center">
              <div className="text-center w-75">
                <span className="h1">Want Cheap books?</span>
                <span>I'll email you when I hear about particularly great book deals.</span>
                <br/>
                <br/>
                <span>I promise not to spam you and will only email you when I genuinely believe you will find it useful.</span>
                <br/>
                <br/>
                <form className="submit-withdrawal" onSubmit={(e) => {handleSubmit(e)}}>                
                  <div className="row justify-content-center mt-2">
                    <div className="col-6">
                      <div className="form-group">
                        <input type="text" className="form-control withdraw-address" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control withdraw-memo" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                      </div>
                      <button type="submit" id="withdrawButton" className="btn btn-lg btn-outline-primary my-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                <div className="row justify-content-center mt-3">
                  <div className="col-12">
                    <span className="h2">Share</span>
                    <TwitterShareButton className="m-1" openShareDialogOnClick="false" url="https://pagesonpages.com" title="Easy way to find Cheap Books">
                      <TwitterIcon />
                    </TwitterShareButton>
                    <FacebookShareButton className="m-1"  openShareDialogOnClick="false" url="https://pagesonpages.com" title="Easy way to find Cheap Books" quote="Easy way to find Cheap Books">
                      <FacebookIcon />
                    </FacebookShareButton>
                    <RedditShareButton className="m-1" openShareDialogOnClick="false" url="https://pagesonpages.com" title="Easy way to find Cheap Books">
                      <RedditIcon />
                    </RedditShareButton>
                    <LinkedinShareButton className="m-1" openShareDialogOnClick="false" url="https://pagesonpages.com" title="Easy way to find Cheap Books" summary="A website that lets you find the cheapest place on the internet where you can buy a certain book. Really helpful.">
                      <LinkedinIcon />
                    </LinkedinShareButton>
                    <TumblrShareButton className="m-1" openShareDialogOnClick="false" url="https://pagesonpages.com" caption="A website that lets you find the cheapest place on the internet where you can buy a certain book. Really helpful." title="Easy way to find Cheap Books">
                      <TumblrIcon />
                    </TumblrShareButton>
                    <WhatsappShareButton className="m-1" openShareDialogOnClick="false" url="https://pagesonpages.com" title="Easy way to find Cheap Books">
                      <WhatsappIcon />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )};
  export default Modal;