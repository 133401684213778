import React from 'react'
import { Select, MenuItem } from '@mui/material';
import './CountrySelector.css'


function CountrySelector({ userCountry, setUserCountry }) {


  const selectCountryHandler = (country) => {
    setUserCountry(country);
  }

  return (
    <Select
      style={{
        border: '1px solid white',
        color: 'white',
        float: 'right',
      }}
      value={userCountry}
      onChange={(e) => selectCountryHandler(e.target.value)}
      >
        <MenuItem key="US" value="US">USA</MenuItem>
        <MenuItem key="UK" value="UK">United Kingdom</MenuItem>
        <MenuItem key="AU" value="AU">Australia</MenuItem>
    </Select>
  )
}


export default CountrySelector;