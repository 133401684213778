import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import Book1 from './images/louise-penny-book-1.jpg';
import Book2 from './images/louise-penny-book-2.jpg';
import Book3 from './images/louise-penny-book-3.jpg';
import Book4 from './images/louise-penny-book-4.jpg';
import Book5 from './images/louise-penny-book-5.jpg';
import Book6 from './images/louise-penny-book-6.jpg';
import Book7 from './images/louise-penny-book-7.jpg';
import Book8 from './images/louise-penny-book-8.jpg';
import Book9 from './images/louise-penny-book-9.jpg';
import Book10 from './images/louise-penny-book-10.jpg';
import Book11 from './images/louise-penny-book-11.jpg';
import Book12 from './images/louise-penny-book-12.jpg';
import Book13 from './images/louise-penny-book-13.jpg';
import Book14 from './images/louise-penny-book-14.jpg';
import Book15 from './images/louise-penny-book-15.jpg';
import Book16 from './images/louise-penny-book-16.jpg';
import Book17 from './images/louise-penny-book-17.jpg';
import Book18 from './images/louise-penny-book-18.jpg';
import LouisePennyBooks from './images/louise-penny-books.jpg';
import LouisePenny from './images/louise-penny-books-in-order.jpg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const LouisePennyBooksInOrder = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Logo size="width-150"/>
    <Helmet>
      <title>Louise Penny Books in Order</title>
      <meta name="description" content="Delve into the captivating world of Inspector Armand Gamache in the right order. Explore the mysteries of the quaint village of Three Pines." />
    </Helmet>
    <div>
      <h1 className="article-title">Louise Penny Books in Order: An Enchanting Mystery Series</h1>
      <small>By Gavin Babji</small>
    </div>
    <img style={{paddingTop: '15px'}} className="image width-750" src={LouisePennyBooks} alt="Louise Penny Books" />

    <section className="section">
      <h2 className="sub-title">The Mystical Journey</h2>
      <p>
        The Inspector Armand Gamache series, penned by Louise Penny, invites readers into the enigmatic world of Three Pines, a small village in Quebec. Chief Inspector Armand Gamache navigates murder investigations while unearthing the darker corners of the human psyche and the idiosyncrasies of this small community. 
      </p>

      <ul>
        <li>"Still Life" (2005)</li>
        <li>"Dead Cold / A Fatal Grace" (2006)</li>
        <li>"The Cruellest Month / The Cruelest Month" (2007)</li>
        <li>"A Rule Against Murder / The Murder Stone" (2008)</li>
        <li>"The Brutal Telling" (2009)</li>
        <li>"Bury Your Dead" (2010)</li>
        <li>"A Trick of the Light" (2011)</li>
        <li>"The Beautiful Mystery" (2012)</li>
        <li>"How the Light Gets In" (2013)</li>
        <li>"The Long Way Home" (2014)</li>
        <li>"The Nature of the Beast" (2015)</li>
        <li>"A Great Reckoning" (2016)</li>
        <li>"Glass Houses" (2017)</li>
        <li>"Kingdom of the Blind" (2018)</li>
        <li>"A Better Man" (2019)</li>
        <li>"All the Devils Are Here" (2020)</li>
        <li>"The Madness of Crowds" (2021)</li>
        <li>"A World of Curiosities" (2022)</li>
        <li>"State of Terror" (2021) co-written by Hillary Clinton</li>
      </ul>
    </section>

    <h2>Search for the cheapest place to buy Louise Penny books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book1} alt="Still Life" />

    <section className="section">
      <h2 className="sub-title">"Still Life" (2005)</h2>
      <p>
      The series commences with a shocking murder in the peaceful village of Three Pines. Jane Neal, a beloved local artist, is found dead in the woods. At first glance, it appears to be a hunting accident, but Chief Inspector Armand Gamache senses something amiss. As he digs deeper into the life of the victim and the secrets of the town, a darker truth begins to unravel.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book2} alt="Dead Cold" />

    <section className="section">
      <h2 className="sub-title">"Dead Cold / A Fatal Grace" (2006)</h2>
      <p>
      In the chilly season of Christmas, Three Pines is shaken up by another murder. CC de Poitiers, a woman as cold-hearted as the winter itself, is found electrocuted in the middle of a frozen lake during the annual curling tournament. Gamache returns to the tranquil village to comb through the layers of deceit and to expose the chilling truth.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book3} alt="The Cruellest Month" />

    <section className="section">
      <h2 className="sub-title">"The Cruellest Month / The Cruelest Month" (2007)</h2>
      <p>
      The arrival of spring brings a new mystery to the charming village of Three Pines. This time, a beloved local is found dead in her own home after participating in a séance, seemingly scared to death. Once again, Gamache and his team are called to solve the case that's more twisted and dark than it first appeared.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book4} alt="A Rule Against Murder" />

    <section className="section">
      <h2 className="sub-title">"A Rule Against Murder / The Murder Stone" (2008)</h2>
      <p>
      The fourth book sees Gamache and his wife, Reine-Marie, celebrating their wedding anniversary at the luxurious, secluded Manoir Bellechasse. But when a long-estranged guest is murdered, Gamache finds himself navigating through family secrets and long-held resentments to find the murderer.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book5} alt="The Brutal Telling" />

    <section className="section">
      <h2 className="sub-title">"The Brutal Telling" (2009)</h2>
      <p>
      When a stranger is found murdered in the village bistro and antiques store, clues lead to the seemingly peaceful local Hermit, creating ripples of suspicion and fear in Three Pines.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book6} alt="Bury Your Dead" />

    <section className="section">
      <h2 className="sub-title">"Bury Your Dead" (2010)</h2>
      <p>
      The story unfolds in two narrative timelines: the first set in the past, revealing a case that haunts Gamache's memory; the second set in the present, focusing on the murder of a controversial historical figure in Quebec City.
      </p>
    </section>


    <section className="section">
      <h2 className="sub-title">"The Hangman" (2010)</h2>
      <p>
      This novella aimed at young adults revolves around Gamache solving a crime in the life of a Three Pines resident. It is an accessible entry point to the series for younger readers or those new to Penny's work.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book7} alt="A Trick of the Light" />

    <section className="section">
      <h2 className="sub-title">"A Trick of the Light" (2011)</h2>
      <p>
      After an artist's celebration of her triumphant opening night, a body is found in her studio. Chief Inspector Gamache is called to investigate the death, digging beneath the idyllic surface of Three Pines.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book8} alt="The Beautiful Mystery" />

    <section className="section">
      <h2 className="sub-title">"The Beautiful Mystery" (2012)</h2>
      <p>
      Gamache and Jean-Guy Beauvoir find themselves in Saint-Gilbert-Entre-les-Loups monastery, investigating the murder of a monk who was part of a famous choir known for its divine music.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book9} alt="How the Light Gets In" />

    <section className="section">
      <h2 className="sub-title">"How the Light Gets In" (2013)</h2>
      <p>
      Gamache faces the most challenging and dangerous case of his career, as a long-buried conspiracy threatens the very foundation of Three Pines, his own Sûreté du Québec, and his own conscience.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book10} alt="The Long Way Home" />

    <section className="section">
      <h2 className="sub-title">"The Long Way Home" (2014)</h2>
      <p>
      Gamache, now retired, is asked to help find a friend’s missing husband, leading to a journey through picturesque Quebec and the deepest parts of the human soul.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book11} alt="The Nature of the Beast" />

    <section className="section">
      <h2 className="sub-title">"The Nature of the Beast" (2015)</h2>
      <p>
      A boy with a reputation for crying wolf sounds the alarm on something truly sinister lurking in the woods of Three Pines, leading to a perilous discovery that has far-reaching consequences.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book12} alt="A Great Reckoning" />

    <section className="section">
      <h2 className="sub-title">"A Great Reckoning" (2016)</h2>
      <p>
      As the new commander of the Sûreté academy, Gamache investigates a map found hidden in the walls of Three Pines, embarking on a quest that holds grave consequences.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book13} alt="Glass Houses" />

    <section className="section">
      <h2 className="sub-title">"Glass Houses" (2017)</h2>
      <p>
      A mysterious figure appears in Three Pines, causing unease among the residents. When it vanishes and a body is discovered, it falls to Gamache to discover the insidious purpose behind the figure's appearance.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book14} alt="Kingdom of the Blind" />

    <section className="section">
      <h2 className="sub-title">"Kingdom of the Blind" (2018)</h2>
      <p>
      Gamache is named an executor of a stranger's will, and a dead body is found in Three Pines. As a deadly web of secrets is untangled, Gamache must face a terrible choice.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book15} alt="A Better Man" />

    <section className="section">
      <h2 className="sub-title">"A Better Man" (2019)</h2>
      <p>
      With catastrophic spring flooding, a daughter's frantic search for her missing father, and dangerous social media exploits, Gamache is pushed to his limits as he investigates.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book16} alt="All the Devils Are Here" />

    <section className="section">
      <h2 className="sub-title">"All the Devils Are Here" (2020)</h2>
      <p>
      In Paris with his family, Gamache is swept up in a sinister web of secrets and lies that stretches back four hundred years, unearthing a shocking truth that will rock his world.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book17} alt="The Madness of Crowds" />

    <section className="section">
      <h2 className="sub-title">"The Madness of Crowds" (2021)</h2>
      <p>
      Gamache is tasked with providing security for a controversial lecture. But when a murder is committed, he must put his skills to the test to uncover the killer amid the madness of crowds.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={Book18} alt="A World of Curiosities" />

    <section className="section">
  <h2 className="sub-title">A World of Curiosities (2022)</h2>
  <p>
    "A World of Curiosities" unravels another chilling tale in the peaceful Quebec village of Three Pines. The story revolves around siblings Sam and Fiona Arsenault, haunted by a past of abuse and the death of their mother, Clotilde. The investigation led by the ever-intrepid Armand Gamache and his deputy Jean-Guy Beauvoir takes a startling turn as it becomes a hunt for a killer hidden behind the enigma of a mysterious painting.
  </p>
  <p>
    Penny weaves the tragic historical event of the Polytechnique Montréal massacre into the fabric of her narrative, poignantly featuring real-life survivor Nathalie Provost. This addition offers a deeper layer of realism and emotional resonance to the plot. 
  </p>
  <p>
    The novel also delves into the early career of Inspector Gamache, providing readers with intriguing insights into his professional evolution. "A World of Curiosities" stands as a testament to Penny's adeptness at blending the dark facets of human nature with a compelling mystery, making it a gripping addition to the series.
  </p>
</section>


    <section className="section">
      <h2 className="sub-title">"State of Terror" (2021), co-written by Hillary Clinton</h2>
      <p>
      In a departure from the Inspector Gamache series, Penny co-writes this gripping novel with Hillary Clinton. It follows the journey of a novice Secretary of State who has joined the administration of her rival. When a series of terrorist attacks throws the global order into chaos, she must unravel a dangerous conspiracy.
      </p>
    </section>



    <section className="section">
      <h2 className="sub-title">In Conclusion</h2>
      <p>
        Louise Penny's Chief Inspector Armand Gamache series presents a captivating journey into the world of murder mysteries and the human psyche. Each book brings with it a new set of challenges, where Gamache must not only solve intricate crimes but also navigate the complexities of his own morality and the close-knit community of Three Pines. Through her rich storytelling and complex characters, Penny provides a profound exploration of love, loss, and the power of the human spirit. If you haven't already, diving into this series is highly recommended.
      </p>
    </section>

    <h2>Search for the cheapest place to buy Louise Penny books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>


    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
      <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
      </div>
    </section>
    <Footer />
  </div>
);

export default LouisePennyBooksInOrder;

