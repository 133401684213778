import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import VerityBookCover from './images/verity-book-sleeves.jpeg';
import ColleenHooverPortrait from './images/colleen-hoover-image.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const VerityColleenHoover = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Helmet>
      <title>Verity Colleen Hoover</title>
      <meta name="description" content="An overview of Verity, an enthralling novel by Colleen Hoover" />
    </Helmet>
    <Logo size="width-150" />
    <div>
      <h1 className="page-title">Verity - A compelling dive into the human psyche by Colleen Hoover</h1>
      <small>By Lucius Small</small>
    </div>

    <img style={{paddingTop: '15px'}} className="image width-750" src={VerityBookCover} alt="Verity Cover - Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">A Tale That Holds Your Attention</h2>
      <p>“Verity” is a captivating narrative that weaves an intricate web of romance, suspense, and startling revelations. The plot pulls you in, keeping you engrossed from the beginning till the surprising end, as it unveils the thin line between reality and deception, love and obsession.</p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">The Genius of Colleen Hoover</h2>
      <p>Colleen Hoover, a New York Times bestselling author, is renowned for her gripping romantic novels. With “Verity,” she takes a thrilling leap into the darker side of love, spinning a tale that revolves around Lowen Ashleigh, a struggling writer who embarks on a journey that tests her beliefs about love and truth. This novel demonstrates Hoover's ability to create intricate, engrossing stories that resonate with readers.</p>

    </section>

    <h2>Search for the cheapest place to buy Verity</h2>
    <SearchBar noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle} noButtons={true}/>

    <section className="section">
      <h2 className="sub-title">Exploring the Depths of Love and Truth</h2>
      <p>“Verity” dives deep into the complexities of love and truth. The characters face dilemmas that make you question the lengths one would go to for love and how much truth we can handle. This engrossing narrative showcases Hoover's talent for creating stories that provoke deep thought and stir emotions.</p>

      <img className="image width-150" src={ColleenHooverPortrait} alt="Portrait of Colleen Hoover" />

      <p>Colleen Hoover has proven time and again her prowess in the romantic thriller genre, and “Verity” is another testament to her brilliant storytelling.</p>

    </section>

    <section className="section">
      <h2 className="sub-title">A Captivating Read for Thriller Lovers</h2>
      <p>“Verity” is a must-read for fans of suspenseful romances. Its carefully crafted plot, relatable characters, and unexpected twists make it an engrossing read. If this overview has sparked your interest, consider purchasing "Verity" or exploring other works by Colleen Hoover. Use the search bar below to find the best deals.</p>

    </section>

    <h2>Search for the cheapest place to buy Verity</h2>

    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section className="articles-section">
      <div className="container">
          <h2>See articles</h2>
          <p>Want to learn more about books, reading, and finding great deals? Check out our articles <a href="/articles">here</a>.
          </p>
          <div className="flex">
          <div className="flex-item">
          <a href="/veritysummary">
              <span>Verity Summary</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/colleenhoover">
              <span>Colleen Hoover</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/colleenhooverbooks">
              <span>Colleen Hoover Books</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/itstartswithus">
              <span>It Starts With Us</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/itendswithus">
              <span>It Ends With Us</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/itendswithussummary">
              <span>It Ends With Us Summary</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/itendswithuspdf">
              <span>It Ends With Us PDF</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/veritysummary">
              <span>Verity Summary</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/veritycolleenhoover">
              <span>Verity Colleen Hoover</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
              <span>Colleen Hoover Books in Order</span>
          </a>
          </div>
          <div className="flex-item">
          <a href="/harrypotterbooksinorder">
              <span>Harry Potter Books in Order</span>
          </a>
          </div>
          <div className="flex-item">
              <a href="/jackcarrbooksinorder">
                  <span>Jack Carr Books In Order</span>
              </a>
          </div>
          <div className="flex-item">
              <a href="/jackcarrbooks">
                  <span>Jack Carr Books</span>
              </a>
          </div>
          <div className="flex-item">
              <a href="/jackcarr">
                  <span>Jack Carr</span>
              </a>
          </div>
          <div className="flex-item">
              <a href="/louisepennybooksinorder">
                  <span>Louise Penny Books In Order</span>
              </a>
          </div>
          <div className="flex-item">
              <a href="/bridgertonbooksinorder">
                  <span>Bridgerton Books In Order</span>
              </a>
          </div>
          <div className="flex-item">
              <a href="/bridgertonbooks">
                  <span>Bridgerton Books</span>
              </a>
          </div>
      </div>
      </div>
  </section>
  <Footer />

  </div>
);

export default VerityColleenHoover;
