import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import JackCarrWithFan from './images/jack-carr-image.jpg';
import JackCarrJoeRogan from './images/jack-carr-joe-rogan.jpg';
import JackCarrBook from './images/Jack-Carr.jpg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const JackCarrBooks = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Logo size="width-150"/>
    <Helmet>
      <title>Jack Carr Books</title>
      <meta name="description" content="Jack Carr Books: The action-packed world of a Navy SEAL" />
    </Helmet>
    <div>
      <h1 className="article-title">Jack Carr Books: The action-packed world of a Navy SEAL</h1>
      <small>By Lucius Small</small>
    </div>
    <img style={{paddingTop: '15px'}} className="image width-750" src={JackCarrBook} alt="Jack Carr Books" />

    <section className="section">
      <h2 className="sub-title">A Comprehensive Bibliography</h2>
      <p>
        Jack Carr is a former Navy SEAL who turned his experiences into a successful career as an author. His action-packed books are renowned for their authenticity and thrilling storylines. As of 2023, his complete list of works includes:
      </p>

      <ul>
        <li>"The Terminal List" (2018)</li>
        <li>"True Believer" (2019)</li>
        <li>"Savage Son" (2020)</li>
        <li>"The Devil's Hand" (2021)</li>
        <li>"In The Blood" (2023)</li>
      </ul>
    </section>

    <h2>Search for the cheapest place to buy Jack Carr books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section className="section">
      <h2 className="sub-title">From Navy SEAL to Thriller Novelist</h2>
      <p>
        Jack Carr's unique strength lies in his ability to translate his real-life experiences as a Navy SEAL into gripping, authentic narratives. His deep understanding of warfare, tactics, and the human condition allow him to create captivating stories that keep readers on the edge of their seats.
      </p>
      <h2 style={{paddingTop: '25px'}} className="sub-title">An Authentic Perspective on Modern Warfare</h2>
      <p>
        Carr's novels stand out for their detailed depiction of modern warfare and espionage. Drawing from his time as a Navy SEAL, Carr infuses his narratives with a level of realism and authenticity that sets his work apart from the average thriller.
      </p>
    </section>

    <img className="image width-200" src={JackCarrJoeRogan} alt="Author Jack Carr" />

    <section className="section">
      <h2 className="sub-title">Creating a Memorable Protagonist</h2>
      <p>
        Carr's protagonist, James Reece, is a former Navy SEAL who finds himself embroiled in a world of conspiracy and danger. Reece's journey across the series reflects Carr's own experiences and offers a compelling character study of a man pushed to his limits.
      </p>
      <h2 style={{paddingTop: '25px'}} className="sub-title">Realism and Attention to Detail</h2>
      <p>
        One of the standout aspects of Carr's work is his attention to detail. From weaponry to tactics to the nuances of the global geopolitical landscape, Carr's meticulous research and first-hand knowledge lend an unprecedented level of realism to his novels.
      </p>
    </section>

    <img className="image width-150" src={JackCarrWithFan} alt="Author Jack Carr" />

    <section className="section">
      <h2 className="sub-title">In Conclusion</h2>
      <p>
        Jack Carr's thrilling narratives, authentic perspective, and memorable characters make him a standout author in the realm of modern military thrillers. Whether you're a fan of action-packed adventures, espionage thrillers, or character-driven narratives, Carr's bibliography offers something for every fan of the genre.
      </p>
    </section>

    <h2>Search for the cheapest place to buy Jack Carr books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
      
    </section>

    <Footer />
  </div>
);

export default JackCarrBooks;
