import React from 'react';
import './css/AdvertiseExtension.css'; // You can create a separate CSS file for styles
import { Helmet } from 'react-helmet';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import SearchBar from '../components/SearchBar';
import ExtensionExample from './images/Extension-example.png';

const AdvertiseExtension = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => {
    return (
        <div className="extension-advertisement">
              <Helmet>
                <title>Browser Extension</title>
                <meta name="description" content="Pages on Pages Browser extension will help you find the cheapest book on all occasions." />
              </Helmet>
            <header>
                <h1>Introducing: Pages on Pages Browser Extension!</h1>
            </header>
            <Logo size="width-150"/>
            <section className="extension-details">
                <div className="extension-description">
                    <h2>Find the Best Book Deals, Effortlessly!</h2>
                    <p>With our new Browser extension, you no longer need to switch tabs or copy-paste titles. Just search for your favorite books and get the best deals right in your browser.</p>
                    <ul>
                        <li>🔍 Automatic search when you look up books online</li>
                        <li>💸 Lists the cheapest copies available</li>
                        <li>🚀 Fast, lightweight, and secure</li>
                    </ul>
                    <a href="https://chrome.google.com/webstore/detail/pages-on-pages/pghlenkokfmgaomdedhndfmmphaokece?hl=en&authuser=1" className="download-button">Add to Browser</a>
                </div>
                <div className="extension-image">
                    <img src={ExtensionExample} alt="Pages on Pages Chrome Extension" />
                </div>
            </section>
            <footer>
                <p>Join thousands of savvy readers who are saving on books every day. Install Pages on Pages Browser Extension now!</p>
            </footer>

            <Footer />
        </div>
    );
}

export default AdvertiseExtension;
