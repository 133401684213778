import axios from 'axios';

const url = 'https://web-scraper-beta.herokuapp.com/title';
// const url = 'http://localhost:8000/title';

const emailUrl = 'https://web-scraper-beta.herokuapp.com/email';
const auUrl = 'https://web-scraper-au.fly.dev/title';

export function getAuBooks(title, country = 'AU'){
  return fetch(`${auUrl}?title=${title}&country=${country}`)
      .then(data => data.json())
};
export function getBooks(title, country = 'US', newOnly = false){
    return fetch(`${url}?title=${title}&country=${country}&newOnly=${newOnly}`)
        .then(data => data.json())
};
export function postEmail(name, email){
  return fetch(`${emailUrl}?name=${name}&email=${email}`, { method: 'POST' })
      .then(data => data.json())
}
export const getVisitorCountry = () => {
    return new Promise((resolve, reject) => {
      window
        .fetch("https://ip2c.org/self")
        .then((response) => response.text())
        .then((data) => {
          const [status, country] = String(data).split(";");
          if (status !== "1") {
            throw new Error("Unable to fetch country");
          }
          resolve(country);
        })
        .catch(() => {
          resolve("US");
        });
    });
  };