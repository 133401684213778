import React from 'react'
import Logo from '../components/Logo';
import Footer from '../components/Footer';

function HackerSentimentPrivacyPolicy() {
  return (
    <>
      <div style={{textAlign: 'left', padding: 20}}>
        <h1>Privacy Policy for Product Sentiment Browser Extension</h1>
        <p>Last Updated: September 20, 2023</p>
        <p>This Privacy Policy describes how the Product Sentiment Chrome Extension (hereinafter referred to as "Extension" or "Product Sentiment") collects, uses, and discloses information, and what choices you have with respect to the information.</p>

        <p>Please carefully review this Privacy Policy before using our Extension.</p>

        <h2>Data Collection and Use</h2>

        <ol>
            <li>
                <strong>Data We Collect:</strong> Product Sentiment accesses the website the user is currently on to perform sentiment analysis. The Extension also accesses online forums, specifically Hacker News, to gather sentiment about the website.
            </li>
            <li>
                <strong>Use of Data:</strong> The data accessed by the Extension is solely used for performing sentiment analysis. We do not store, save, or archive the data from the website you are visiting or from the online forums.
            </li>
            <li>
                <strong>No Personal Data:</strong> Product Sentiment does not collect or store any personally identifiable information about the users. We are committed to user privacy and ensuring that your browsing experience remains private.
            </li>
        </ol>

        <h2>Data Sharing and Disclosure</h2>

        <ol>
            <li>
                <strong>No Sharing:</strong> We do not share any data accessed by the Extension with third parties.
            </li>
            <li>
                <strong>No Selling:</strong> We will not sell or rent user data to anyone.
            </li>
        </ol>

        <h2>Data Security</h2>

        <p>We implement and maintain reasonable and appropriate security procedures and practices appropriate to the nature of the information to protect the data accessed by the Extension from unauthorized access, destruction, use, modification, or disclosure.</p>

        <h2>User Rights</h2>

        <ol>
            <li>
                <strong>Access and Control:</strong> Since we do not collect or store any user data, there is no data for users to access or control.
            </li>
            <li>
                <strong>Uninstallation:</strong> If at any time you wish to stop using the Extension, you can simply uninstall the Extension from your Google Chrome browser.
            </li>
        </ol>

        <h2>Children’s Privacy</h2>

        <p>Our Extension is not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>

        <h2>Updates to this Privacy Policy</h2>

        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this Privacy Policy periodically for any changes.</p>

        <h2>Contact Information</h2>

        <p>For any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:hackernewssentiment@gmail.com">hackernewssentiment@gmail.com</a></p>

      </div>
    </>  
  )
}

export default HackerSentimentPrivacyPolicy;