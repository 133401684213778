import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import ColleenHooverBooksShelf from './images/colleen-hoover-books-shelf.png';
import ColleenHooverHoldingBook from './images/colleen-hoover-holding-book.jpeg';
import ColleenHooverBook from './images/colleen-hoover-books.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const ColleenHooverBooks = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Logo size="width-150"/>
    <Helmet>
      <title>Colleen Hoover Books</title>
      <meta name="description" content="Colleen Hoover Books: The good, the bad, and the ugly" />
    </Helmet>
    <div>
      <h1 className="article-title">Colleen Hoover Books: The good, the bad, and the ugly</h1>
      <small>By Lucius Small</small>
    </div>
    <img style={{paddingTop: '15px'}} className="image width-750" src={ColleenHooverBook} alt="Colleen Hoover Books" />

    <section className="section">
      <h2 className="sub-title">A Comprehensive Bibliography</h2>
      <p>
        Colleen Hoover is a prolific writer, with an extensive bibliography that continues to grow each year. As of 2023, her complete list of works includes:
      </p>

      <ul>
        <li>"Slammed" (2012)</li>
        <li>"Point of Retreat" (2012)</li>
        <li>"This Girl" (2013)</li>
        <li>"Hopeless" (2012)</li>
        <li>"Losing Hope" (2013)</li>
        <li>"Finding Cinderella" (2013)</li>
        <li>"Maybe Someday" (2014)</li>
        <li>"Maybe Not" (2014)</li>
        <li>"Ugly Love" (2014)</li>
        <li>"Confess" (2015)</li>
        <li>"November 9" (2015)</li>
        <li>"It Ends with Us" (2016)</li>
        <li>"Without Merit" (2017)</li>
        <li>"All Your Perfects" (2018)</li>
        <li>"Verity" (2018)</li>
        <li>"Regretting You" (2019)</li>
        <li>"Heart Bones" (2020)</li>
        <li>"Layla" (2020)</li>
        <li>"It Starts with Us" (2022)</li>
        <li>"Reminders of Him" (2022)</li>
        <li>"Too Late: Definitive Edition" (2023)</li>
      </ul>

      <p>Collaborative Works:</p>

      <ul>
        <li>"Never Never: Part One" (2015, with Tarryn Fisher)</li>
        <li>"Never Never: Part Two" (2015, with Tarryn Fisher)</li>
        <li>"Never Never: Part Three" (2016, with Tarryn Fisher)</li>
        <li>"Maybe Now" (2018)</li>
      </ul>
    </section>

    <h2>Search for the cheapest place to buy Colleen Hoover books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>


    <section className="section">
      <h2 className="sub-title">Crafting Emotionally Resonant Narratives</h2>
      <p>
        Hoover's unique strength lies in her ability to create emotionally resonant narratives that connect with readers on a profound level. Her characters are deeply flawed, often grappling with past trauma or personal struggles, yet they're also profoundly relatable. This unique approach allows readers to empathize with characters they might otherwise find difficult to understand, creating a deep sense of emotional resonance that elevates Hoover's work.
      </p>
      <h2 style={{paddingTop: '25px'}} className="sub-title">Breaking Boundaries in Romance</h2>
      <p>
        Hoover's romance novels are notable for their exploration of challenging themes, from abusive relationships in "It Ends with Us" to the complexities of grief and forgiveness in "Regretting You." "It Starts with Us" serves as a follow-up to "It Ends with Us", diving further into the aftermath of an abusive relationship and exploring the process of healing and moving forward.
      </p>
      <p>
        "Ugly Love," "Maybe Someday," and "November 9" stand out as innovative experiments with narrative structure, playing with perspectives and timelines to create compelling love stories that feel fresh and original.
      </p>
    </section>

    <img className="image width-200" src={ColleenHooverHoldingBook} alt="Author Colleen Hoover" />


    <section className="section">
      <h2 className="sub-title">Venturing into New Territory</h2>
      <p>
        Not content to stick to one genre, Hoover has also ventured into the supernatural with "Layla," introducing elements of paranormal romance into her oeuvre. The novel demonstrates Hoover's versatility as a writer, seamlessly blending her knack for emotional storytelling with an entirely new genre.
      </p>
      <h2 style={{paddingTop: '25px'}} className="sub-title">Expanding the Hopeless Universe</h2>
      <p>
        Hoover's "Hopeless" series stands out for its exploration of difficult topics like abuse and suicide. "Hopeless" and its companion novel, "Losing Hope," tell the same story from two different perspectives, offering a nuanced exploration of trauma and healing. "Finding Cinderella," a novella, provides further insight into the lives of secondary characters, expanding the universe of "Hopeless" and giving readers a deeper understanding of its world.
      </p>
      <h2 style={{paddingTop: '25px'}} className="sub-title">A Commitment to Realism</h2>
      <p>
        In all her works, Hoover displays a commitment to portraying life as it is, rather than as we might wish it to be. Her novels don't shy away from the messy, complicated aspects of love and life, offering raw, honest depictions of relationships and personal growth. Whether she's writing about the aftermath of a traumatic event or the complexities of falling in love, Hoover's commitment to realism sets her work apart.
      </p>
    </section>

    <img className="image width-150" src={ColleenHooverBooksShelf} alt="Author Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">The Power of Hope</h2>
      <p>
        Despite the challenging themes that run through Hoover's work, a thread of hope weaves its way through each of her novels. Her characters often find ways to overcome their hardships, grow from their experiences, and find happiness in unexpected places. This focus on resilience and hope is a hallmark of Hoover's work, offering readers an uplifting message amidst the turmoil.
      </p>
      <h2 style={{paddingTop: '25px'}} className="sub-title">In Conclusion</h2>
      <p>
        Colleen Hoover's rich, varied body of work marks her as one of the most distinctive voices in contemporary literature. Her compelling characters, emotionally resonant narratives, and willingness to tackle challenging themes have made her a favorite among readers around the world. Whether you're a fan of heart-wrenching romance, character-driven drama, or supernatural thrillers, Hoover's bibliography offers something for everyone.
      </p>
    </section>

    <h2>Search for the cheapest place to buy Colleen Hoover books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
      
    </section>


  <Footer />
  </div>
);

export default ColleenHooverBooks;
