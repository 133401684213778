import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import VerityBookSleeves from './images/verity-book-sleeves.jpeg';
import ColleenHooverBooks from './images/colleen-hoover-books.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const VeritySummary = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Helmet>
      <title>Verity Colleen Hoover Summary</title>
      <meta name="description" content="A summary of Verity" />
    </Helmet>
    <Logo size="width-150" />
    <div>
      <h1 className="page-title">Verity Summary - A chilling journey into the dark corners of a writer's life</h1>
      <small>By Lucius Small</small>
    </div>

    <img style={{paddingTop: '15px'}} className="image width-750" src={VerityBookSleeves} alt="Verity Cover - Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">Desperation and a Golden Opportunity</h2>
      <p>Lowen Ashleigh, a struggling writer, is barely holding on when life throws her a lifeline. Jeremy Crawford, the husband of renowned author Verity Crawford, hires Lowen to complete the remaining books in his wife's successful series, unable to do so due to her injuries.</p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">A Dark Discovery</h2>
      <p>Armed with years of Verity's notes and outlines, Lowen steps into the Crawford home, hoping to piece together enough material to start her assignment. However, what she stumbles upon instead is Verity's unfinished autobiography. The chilling revelations within these pages recount the horrifying night that forever changed the Crawford family.</p>

      <p>Lowen decides to hide the manuscript from Jeremy, fearing its devastating impact on the grieving husband. Yet as her feelings for Jeremy deepen, she realizes the potential benefits if Jeremy were to read his wife's shocking truths. Despite his devoted love for his incapacitated wife, a revelation this shocking would surely shatter his affection.</p>

    </section>

    <h2>Search for the cheapest place to buy Verity</h2>
    <SearchBar noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle} noButtons={true}/>

    <section className="section">
      <h2 className="sub-title">A Battle Between Love and Truth</h2>
      <p>As Lowen wrestles with her conscience and her escalating feelings for Jeremy, the question remains: Will she reveal the hidden manuscript? This struggle forms the crux of "Verity," setting the stage for a gripping narrative fraught with suspense, emotional turmoil, and unexpected twists.</p>

      <p>“Verity” explores the intricate dynamic between love and truth, where one damaging revelation could shatter a loving relationship. Yet, withholding such a reality could prove equally destructive. In this finely-crafted narrative, Colleen Hoover once again demonstrates her masterful storytelling, compelling readers to delve into the darkest corners of the human psyche.</p>

    </section>
    <img className="image width-150" src={ColleenHooverBooks} alt="Verity Art - Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">An Unforgettable Conclusion</h2>
      <p>The conclusion of “Verity” sees Lowen grappling with her choices in the aftermath of her discovery. Does she protect Jeremy from the harrowing truth or unveil the reality that could shatter his world?</p>

      <p>The final pages of “Verity” are a testament to the power of truth and the devastating consequences of dark secrets. It’s a narrative that leaves a lasting impression, reminding readers of the potent blend of suspense and emotional depth that is characteristic of Colleen Hoover's storytelling.</p>

      <p>If you've found this Verity summary intriguing, you might want to purchase the book or explore other works by Colleen Hoover. Use the search bar below to find the best deals.</p>

    </section>

    <h2>Search for the cheapest place to buy Verity</h2>

    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
    </section>

    <Footer />
  </div>
);

export default VeritySummary;
