import React, { useEffect } from 'react';
import { Grow } from '@mui/material'
import Logo from '../components/Logo';
import '../css/theme.css';
import SearchBar from '../components/SearchBar';
import {isMobile} from 'react-device-detect';
import ReactGA from 'react-ga';
import CountrySelector from '../components/CountrySelector/CountrySelector';
import { Container } from '@mui/system';
import Footer from '../components/Footer';



const Homescreen = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, userCountry, setUserCountry, buttonStatus, setButtonStatus, submitted, setSubmitted, title, setTitle }) => {

    useEffect(() => {
      ReactGA.pageview(window.location.pathname); 
      document.title = 'Pages on Pages: The Cheapest Place to Buy Books Online'; // Set the title for SEO 
        
    }, [])

    return (
        <>
            <Grow in>
                <div className="main-container">

                    <section style={{ height: '100vh' }} className="bg-dark text-white jarallax" data-jarallax data-speed="0.2">
                        
                        <img alt="books" src="/books.jpg" className="bg-image opacity-40 jarallax-img" />
                        <div className="row mr-2 mb-3">
                            <div className="container">
                                <CountrySelector userCountry={userCountry} setUserCountry={setUserCountry} color={'white'}/>
                            </div>
                        </div>
                        <Container>
                            <SearchBar noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle} />
                        </Container>

                        <div className="container">
                            <div className="row text-center justify-content-center section-intro">
                                <div className="col-12 col-md-10 col-lg-8 mt-1 mb-3">
                                    <h1 className="display-3">Find a cheap book!</h1>
                                    <h4>We scour the internet to find the cheapest book, so you don't have to.</h4>
                                </div>
                                {/*end of col*/}
                            </div>
                            <Logo size={"width-150"}/>
                            { !isMobile &&
                                <div style={{marginTop: '30px'}} className="extension-description">
                                    <h2 style={{color: 'white'}}>Check out our new Browser Extension!</h2>
                                    <div className='row' style={{justifyContent: 'center'}}>
                                        <a style={{margin: '10px'}} target="_blank" href="/extension" className="btn btn-lg btn-success">Learn More</a>
                                        <a style={{margin: '10px'}} target="_blank" href="https://chrome.google.com/webstore/detail/pages-on-pages/pghlenkokfmgaomdedhndfmmphaokece?hl=en&authuser=1" className="btn btn-lg btn-success">Add to Browser</a>
                                    </div>
                                </div>
                            }

                        </div>
                                {/*end of row*/}
                        {/*end of container*/}
                    </section>
                    {/*end of section*/}
                    {/* Value proposition sections */}
                    <section className="value-section">
                        <div className="container">
                            <h2>Why choose Pages on Pages?</h2>
                            <p>We know how important it is to find books at affordable prices. That's why we do all the hard work of finding the cheapest books for you. Whether you're looking for textbooks, novels, or any other kind of book, we've got you covered.</p>
                        </div>
                    </section>
                    <section className="value-section">
                        <div className="container">
                            <h2>How does Pages on Pages work?</h2>
                            <p>Our system searches multiple online stores to find the best prices for the book you want. You simply need to search for the book, and we provide you with a list of places where you can buy it at the cheapest prices.</p>
                        </div>
                    </section>
                    {/* See articles section */}
                    <section className="articles-section">
                        <div className="container">
                            <h2>See articles</h2>
                            <p>Want to learn more about books, reading, and finding great deals? Check out our articles <a href="/articles">here</a>.
                            </p>
                            <div className="flex">
                            <div className="flex-item">
                            <a href="/colleenhoover">
                                <span>Colleen Hoover</span>
                            </a>
                            </div>
                            <div className="flex-item">
                            <a href="/colleenhooverbooks">
                                <span>Colleen Hoover Books</span>
                            </a>
                            </div>
                            <div className="flex-item">
                            <a href="/itstartswithus">
                                <span>It Starts With Us</span>
                            </a>
                            </div>
                            <div className="flex-item">
                            <a href="/itendswithus">
                                <span>It Ends With Us</span>
                            </a>
                            </div>
                            <div className="flex-item">
                            <a href="/itendswithussummary">
                                <span>It Ends With Us Summary</span>
                            </a>
                            </div>
                            <div className="flex-item">
                            <a href="/itendswithuspdf">
                                <span>It Ends With Us PDF</span>
                            </a>
                            </div>
                            <div className="flex-item">
                            <a href="/veritysummary">
                                <span>Verity Summary</span>
                            </a>
                            </div>
                            <div className="flex-item">
                                <a href="/veritybook">
                                    <span>Verity Book</span>
                                </a>
                            </div>
                            <div className="flex-item">
                                <a href="/veritycolleenhoover">
                                    <span>Verity Colleen Hoover</span>
                                </a>
                            </div>
                            <div className="flex-item">
                            <a href="/colleenhooverbooksinorder">
                                <span>Colleen Hoover Books in Order</span>
                            </a>
                            </div>
                            <div className="flex-item">
                            <a href="/harrypotterbooksinorder">
                                <span>Harry Potter Books in Order</span>
                            </a>
                            </div>
                            <div className="flex-item">
                                <a href="/jackcarrbooksinorder">
                                    <span>Jack Carr Books In Order</span>
                                </a>
                            </div>
                            <div className="flex-item">
                                <a href="/jackcarrbooks">
                                    <span>Jack Carr Books</span>
                                </a>
                            </div>
                            <div className="flex-item">
                                <a href="/jackcarr">
                                    <span>Jack Carr</span>
                                </a>
                            </div>
                            <div className="flex-item">
                                <a href="/louisepennybooksinorder">
                                    <span>Louise Penny Books In Order</span>
                                </a>
                            </div>
                            <div className="flex-item">
                                <a href="/bridgertonbooksinorder">
                                    <span>Bridgerton Books In Order</span>
                                </a>
                            </div>
                            <div className="flex-item">
                                <a href="/bridgertonbooks">
                                    <span>Bridgerton Books</span>
                                </a>
                            </div>
                        </div>
                        </div>
                    </section>
                    {/*end of section*/}
                </div>
            </Grow>
            <Footer/>
        </>
        
    );
}

export default Homescreen;