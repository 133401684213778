import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import BridgertonDuke from './images/bridgerton-book-1.jpg';
import BridgertonViscount from './images/bridgerton-book-2.jpg';
import BridgertonOffer from './images/bridgerton-book-3.jpg';
import BridgertonRake from './images/bridgerton-book-4.jpg';
import BridgertonKnight from './images/bridgerton-book-5.jpg';
import BridgertonCountess from './images/bridgerton-book-6.jpg';
import BridgertonDuchess from './images/bridgerton-book-7.jpg';
import BridgertonSecret from './images/bridgerton-book-8.jpg';
import BridgertonBooks from './images/bridgerton-books-on-order.png';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const BridgertonBooksInOrder = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Logo size="width-150"/>
    <Helmet>
      <title>Bridgerton Books in Order</title>
      <meta name="description" content="Explore the romantic world of the Bridgerton series in the right order. Dive into the enchanting journey of the Bridgerton family." />
    </Helmet>
    <div>
      <h1 className="article-title">Bridgerton Books in Order: An Enchanting Journey</h1>
      <small>By Gavin Babji</small>
    </div>
    <img style={{paddingTop: '15px'}} className="image width-750" src={BridgertonBooks} alt="Bridgerton Books" />

    <section className="section">
      <h2 className="sub-title">The Enchanting Journey</h2>
      <p>
        The Bridgerton series, authored by Julia Quinn, takes readers on a romantic journey through the lives of the Bridgerton family, eight siblings living in Regency era England, as they find love and happiness.
      </p>

      <ul>
        <li>"The Duke and I" (2000)</li>
        <li>"The Viscount Who Loved Me" (2000)</li>
        <li>"An Offer From a Gentleman" (2001)</li>
        <li>"Romancing Mister Bridgerton" (2002)</li>
        <li>"To Sir Phillip, With Love" (2003)</li>
        <li>"When He Was Wicked" (2004)</li>
        <li>"It's in His Kiss" (2005)</li>
        <li>"On the Way to the Wedding" (2006)</li>
      </ul>
    </section>

    <h2>Search for the cheapest place to buy Bridgerton books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <img style={{paddingTop: '15px'}} className="book-card" src={BridgertonDuke} alt="Bridgerton Books" />

<section className="section">
  <h2 className="sub-title">"The Duke and I" (2000)</h2>
  <p>
  In the first book of the series, we are introduced to the Bridgerton family, focusing on the eldest daughter, Daphne. When Simon Basset, Duke of Hastings and close friend to Daphne's older brother, returns to London, they form a pact to engage in a pretend courtship. The goal is to keep Simon free from the marriage-minded society mothers and increase Daphne's value on the marriage market. However, their plan gets complicated when they begin to fall for each other.
  </p>
</section>

<img style={{paddingTop: '15px'}} className="book-card" src={BridgertonViscount} alt="Bridgerton Books" />

<section className="section">
  <h2 className="sub-title">"The Viscount Who Loved Me" (2000)</h2>
  <p>
  Anthony Bridgerton, the eldest son, is determined to marry and fulfill his duty as the head of the family. However, he wants a marriage of convenience, free of emotions. His path crosses with Kate Sheffield, the elder of the Sheffield sisters, who is quite opposite to her sister whom Anthony initially intended to court. As they spend more time together, an unexpected romance begins to bloom between them.
  </p>
</section>

<img style={{paddingTop: '15px'}} className="book-card" src={BridgertonRake} alt="Bridgerton Books" />

<section className="section">
  <h2 className="sub-title">"An Offer from a Gentleman" (2001)</h2>
  <p>
  Sophie Beckett, a daughter of an Earl, is relegated to the role of a servant by her stepmother. At a masquerade ball, she falls for Benedict Bridgerton, who is smitten with her without knowing her true identity. The book traces Sophie's journey as she lives under her cruel stepmother and dreams of the man she met at the ball, and how Benedict, unable to forget the mysterious woman, happens upon her in the most unlikely of places.
  </p>
</section>

<img style={{paddingTop: '15px'}} className="book-card" src={BridgertonOffer} alt="Bridgerton Books" />

<section className="section">
  <h2 className="sub-title">"Romancing Mister Bridgerton" (2002)</h2>
  <p>
  Penelope Featherington has been in love with her best friend's brother, Colin Bridgerton, for years. When she discovers that Colin has feelings for her, their relationship takes a romantic turn. As they navigate their new relationship, they also must handle the scandal surrounding Lady Whistledown's true identity.
  </p>
</section>

<img style={{paddingTop: '15px'}} className="book-card" src={BridgertonKnight} alt="Bridgerton Books" />

<section className="section">
  <h2 className="sub-title">"To Sir Phillip, With Love" (2003)</h2>
  <p>
  Eloise Bridgerton begins corresponding with Sir Phillip Crane following his wife's death. After he suggests they marry, Eloise, curious and adventurous, runs off to meet him, leading to a marriage proposal in person. The story revolves around their relationship, starting from a marriage of convenience to something much more.
  </p>
</section>

<img style={{paddingTop: '15px'}} className="book-card" src={BridgertonCountess} alt="Bridgerton Books" />

<section className="section">
  <h2 className="sub-title">"When He Was Wicked" (2004)</h2>
  <p>
  Francesca Bridgerton is a widow who has long considered Michael Stirling, her late husband's cousin, a dear friend. Michael has secretly loved Francesca for years but feels guilty for his feelings. The story narrates their transition from friends to lovers, handling their shared grief, guilt, and the prospect of a second love.
  </p>
</section>

<img style={{paddingTop: '15px'}} className="book-card" src={BridgertonDuchess} alt="Bridgerton Books" />

<section className="section">
  <h2 className="sub-title">"It's In His Kiss" (2005)</h2>
  <p>
  Hyacinth Bridgerton, the youngest daughter, helps Gareth St. Clair translate his grandmother's diary from Italian. Gareth is in dire need of finding a clue in the diary to save his inheritance. As they spend time together, an unexpected romance ensues. This story offers humor, love, and a hint of mystery.
  </p>
</section>

<img style={{paddingTop: '15px'}} className="book-card" src={BridgertonSecret} alt="Bridgerton Books" />

<section className="section">
  <h2 className="sub-title">"On the Way to the Wedding" (2006)</h2>
  <p>
  Gregory Bridgerton believes in love at first sight, and he experiences it when he meets Hermione Watson. Unfortunately, Hermione is smitten with another man. Her pragmatic best friend, Lucy Abernathy, attempts to assist Gregory in winning Hermione's heart, leading to unexpected feelings between Lucy and Gregory.
  </p>
</section>



    <section className="section">
      <h2 className="sub-title">In Conclusion</h2>
      <p>
        The Bridgerton series is a must-read for any fan of romance and historical fiction. The captivating narrative, the character development, and the intricate plot make it an unforgettable reading experience. Whether you are a new reader or revisiting the series, following the books in order will lead you through the enchanting journey crafted by Julia Quinn.
      </p>
    </section>

    <h2>Search for the cheapest place to buy Bridgerton books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
      <div className="flex-item">
        <a href="/colleenhoover">
            <span>Colleen Hoover</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/itstartswithus">
            <span>It Starts With Us</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/itendswithus">
            <span>It Ends With Us</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/veritysummary">
            <span>Verity Summary</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
        </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
    </section>

    <Footer />
  </div>
);

export default BridgertonBooksInOrder;
