import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import ItEndsWithUsTable from './images/it-ends-with-us-table.jpeg';
import ItEndsWithUsBlakeLively from './images/it-ends-with-us-blake-lively.jpeg';
import ItEndsWithUsArt from './images/it-ends-with-us-art.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const ItEndsWithUs = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Helmet>
      <title>It Ends With Us</title>
      <meta name="description" content="It Ends With Us - Colleen Hoover's darkest and most popular read" />
    </Helmet>
    <Logo size="width-150" />
    <div>
      <h1 className="page-title">A Deep Dive into Colleen Hoover's Classic 'It Ends with Us'</h1>
      <small>By Lucius Small</small>
    </div>

    <img style={{paddingTop: '15px'}} className="image width-750" src={ItEndsWithUsBlakeLively} alt="Blake Lively - It Ends With Us" />

    <section className="section">
      <h2 className="sub-title">The Start of an Emotional Odyssey</h2>
      <p>Before the sequel, "It Starts with Us," there was the remarkable beginning - "It Ends with Us." In this 2016 classic, Colleen Hoover presents a gripping tale of love, pain, and resilience. This story doesn't just end with us; it lays the foundation for an emotional journey that has resonated with readers worldwide.</p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">The Emergence of Atlas</h2>
      <p>"It Ends with Us" offers the introduction to Atlas, who would become a fan-favorite character in Hoover's narrative universe. This book illuminates the complexities of his character, his relationship with Lily, and the unique emotional arc that sets the stage for "It Starts with Us."</p>

    </section>

    <h2>Search for the cheapest place to buy It Ends With Us</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section className="section">
      <h2 className="sub-title">"A Tumultuous Love Story</h2>
      <p>"It Ends with Us" revolves around Lily's passionate but tumultuous relationship with Ryle. The narrative offers a sincere portrayal of love and its pitfalls. Hoover handles the topic with empathy and realism, highlighting that it's not merely about romantic love, but rather about navigating the often murky waters of interpersonal relationships. It ends with us; it signifies the end of one phase and the beginning of another in life's ever-evolving journey.</p>

      <h2 style={{paddingTop: '25px'}} className="sub-title">Switching Perspectives and Deeper Insights</h2>
      <p>Switching between past and present, "It Ends with Us" provides readers with a panoramic view of Lily's life. The narrative employs clever storytelling techniques, including diary entries, to offer deeper insights into her character and experiences. Through this intricate narrative structure, Hoover demonstrates the essence of the title - "It Ends with Us." Our actions, decisions, and experiences shape our destinies, and indeed, everything ends with us.</p>

    </section>
    <img className="image width-150" src={ItEndsWithUsArt} alt="Colleen Hoover - It Ends With Us" />


    <section className="section">
      <h2 className="sub-title">A Journey into the Past</h2>
      <p>Hoover masterfully explores the characters' pasts in "It Ends with Us," particularly Atlas's. Readers get an intimate view of his life experiences, which adds depth to his character and influences his future decisions. The exploration of past experiences in the narrative fabric of "It Ends with Us" is a testament to Hoover's understanding that our histories profoundly shape our present and future.</p>

      <h2 style={{paddingTop: '25px'}} className="sub-title">Addressing Real Issues</h2>
      <p>The strength of "It Ends with Us" lies in its raw and honest depiction of life's challenges. As Lily grapples with the realities of her relationship with Ryle and the shadows of her past, the narrative deals with significant issues such as domestic violence. Hoover handles these issues with the gravity they deserve, ensuring that "It Ends with Us" is not merely a romance novel but a commentary on pressing societal issues.</p>

    </section>

    <img className="image width-150" src={ItEndsWithUsTable} alt="Author Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">Conclusion: A Milestone in Contemporary Fiction</h2>
      <p>With "It Ends with Us," Hoover delivers an emotionally charged, thought-provoking, and engaging narrative. The title serves not only as a nod to the storyline but also as a thematic statement of the book's deeper message. Whether it's about ending cycles of abuse, taking control of one's life, or making tough decisions, "It Ends with Us." This novel stands as a powerful testament to Hoover's storytelling prowess and continues to resonate with readers, reinforcing the belief that each chapter of our lives, no matter how difficult, ends with us.</p>

    </section>


    <h2>Search for the cheapest place to buy It Ends With Us</h2>

    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
        <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
    </section>

    <Footer />
  </div>
);

export default ItEndsWithUs;
