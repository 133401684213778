import ReactGA from 'react-ga';
import styles from './Book.css'
const Book = ({ userCountry, lastShowCount, setLastShowCount, bookList, isCardClicked, setCardClicked, anchor, setAnchor }) => {


  const handleCardClick = (e, title, price) => {
    setAnchor(e.currentTarget)
    ReactGA.event({
      category: "click",
      action: "clicked on card",
      label: title,
      value: price
    });
    if (lastShowCount == 1){
      setCardClicked(true);
      setLastShowCount(5)
    } else {
      setLastShowCount(lastShowCount - 1)
    }
  
  }
  const countryPricing = () => {
    if (userCountry === "AU"){
      return "$AU"
    } else if(userCountry == "UK" || userCountry == "GB") {
      return "£"
    }
    else{
      return "$US"
    }
  }


  return (


    <>
      {bookList.map(book => 
          
        <li key={book.link} className="col-12 col-md-6 col-lg-4">
          <a href={book.link} target="_blank" rel="noreferrer">
            <div className="card primaryBtn" onClick={(e) => {
                  handleCardClick(e, book.title, book.price)
            }}>
              {book.Platform && <div className="card-badge" style={{backgroundColor: "#02B875", color: "white"}}>Buy on {book.Platform}</div>}
                <img className="card-img-top" src={book.image} alt={book.title} height="450" />
              <div className="card-body">
                <h4 className="card-title nowrap">{book.title.length > 26 ? book.title.substring(0,26) + "..." : book.title}</h4>
                <p className="card-text text-body">Price: {countryPricing()} {book.totalCost} <br/><small>incl. delivery</small></p>
                <small className="text-body">ETA: {book.DeliveryTime}</small>
              </div>
              <div className="card-footer card-footer-borderless d-flex justify-content-between">
                <div className="text-small">
                  <ul className="list-inline">
                    <li className="list-inline-item"><i className="icon-heart mr-1" />{book.author}</li>
                    <li className="list-inline-item"><i className="icon-message mr-1" />Condition: {book.Condition}</li>
                  </ul>
                </div>
              </div>
            </div>
          </a>
        </li>
      )}
    </>

  );
}

export default Book;