import React from 'react';
import BridgertonBook from './images/bridgerton-books-on-order.png';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import BridgertonCast from './images/bridgerton-cast.jpg';
import BridgertonDuke from './images/bridgerton-book-1.jpg';


const BridgertonBooks = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => 
   (
    <div className="article">
    <Logo size="width-150"/>
    <Helmet>
      <title>Bridgerton Books</title>
      <meta name="description" content="Explore the romantic world of the Bridgerton series. Dive into the enchanting journey of the Bridgerton family." />
    </Helmet>
        <div>
          <h1 className="article-title">Bridgerton Books</h1>
          <small>By Gavin Babji</small>

          <img style={{paddingTop: '15px'}} className="image width-750" src={BridgertonBook} alt="Bridgerton Books" />

            <p>The Bridgerton Series is a collection of eight novels, written by Julia Quinn, each revolving around one of the children of the late Viscount Bridgerton: Anthony, Benedict, Colin, Daphne, Eloise, Francesca, Gregory, and Hyacinth. The books are set in Regency England, offering a delightful mix of wit, charm, and romance.</p>
            
            <ul>
                <li>"The Duke and I" (2000)</li>
                <li>"The Viscount Who Loved Me" (2000)</li>
                <li>"An Offer From a Gentleman" (2001)</li>
                <li>"Romancing Mister Bridgerton" (2002)</li>
                <li>"To Sir Phillip, With Love" (2003)</li>
                <li>"When He Was Wicked" (2004)</li>
                <li>"It's In His Kiss" (2005)</li>
                <li>"On the Way to the Wedding" (2006)</li>
            </ul>
          
            <h2>Search for the cheapest place to buy Bridgerton books</h2>
            <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

            <img style={{paddingTop: '15px'}} className="image width-750" src={BridgertonCast} alt="Bridgerton Books" />


            <p>From "The Duke and I," published in 2000, to "On the Way to the Wedding" in 2006, each story unfolds a unique and enticing love tale of a Bridgerton sibling. In each book, they navigate the complexities of high society and its norms while discovering unexpected love.</p>

            <p>These books blend historical detail with modern sensibility, featuring engaging storytelling and cleverly intertwined love stories. Each novel stands alone, but the series is connected and enhanced by the family bond, the charming characters, and the infamous, anonymous gossip writer, Lady Whistledown.</p>

            <p>Quinn's captivating writing, filled with humor and emotional depth, has endeared millions of readers worldwide, making this series a favorite amongst historical romance lovers. If you're a fan of historical romance, with its balls, gowns, and a dash of wit and intrigue, the Bridgerton Series is a must-read!</p>

            <img style={{paddingTop: '15px'}} className="book-card" src={BridgertonDuke} alt="Bridgerton Books" />
            <h2>Search for the cheapest place to buy Bridgerton books</h2>
            <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

            <section>
              <h2 className="sub-title">See more like this</h2>
              <div className="flex">
              <div className="flex-item">
                <a href="/colleenhoover">
                    <span>Colleen Hoover</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/colleenhooverbooks">
                    <span>Colleen Hoover Books</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/itstartswithus">
                    <span>It Starts With Us</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/itendswithus">
                    <span>It Ends With Us</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/itendswithussummary">
                    <span>It Ends With Us Summary</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/itendswithuspdf">
                    <span>It Ends With Us PDF</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/veritysummary">
                    <span>Verity Summary</span>
                </a>
                </div>
                <div className="flex-item">
                <a href="/colleenhooverbooksinorder">
                    <span>Colleen Hoover Books in Order</span>
                </a>
                </div>
                <div className="flex-item">
                  <a href="/jackcarrbooksinorder">
                    <span>Jack Carr Books In Order</span>
                  </a>
                </div>
                <div className="flex-item">
                    <a href="/jackcarrbooks">
                        <span>Jack Carr Books</span>
                    </a>
                </div>
                <div className="flex-item">
                    <a href="/jackcarr">
                        <span>Jack Carr</span>
                    </a>
                </div>
                <div className="flex-item">
                    <a href="/louisepennybooksinorder">
                        <span>Louise Penny Books In Order</span>
                    </a>
                </div>
              </div>
            </section>

            <Footer />  
        </div>
      </div>
    );


export default BridgertonBooks;
