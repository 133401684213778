import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ReactGA from 'react-ga';


const SearchBar = ( { noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle, noButtons } ) => {
    let navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonStatus(false)
        ReactGA.event({
          category: "search",
          action: "submitted search",
          label: title
        });
        let submitCount = submitted+1;
        setSubmitted(submitCount);
        navigate("/BookResults")
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <form className="card card-sm" onSubmit={handleSubmit}>
              <div className="card-body row no-gutters align-items-center">
                <div className="col-auto">
                  <i className="icon-magnifying-glass h4 text-body" />
                </div>
                {/*end of col*/}
                <div className="col">
                  <input className="form-control form-control-lg form-control-borderless" type="search" placeholder="Search for a title, author or ISBN" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                {/*end of col*/}
                <div className="col-auto">
                  <button disabled={!buttonStatus} className="btn btn-lg btn-success" type="submit">Search</button>
                </div>
                {/*end of col*/}
              </div>
            </form>
          </div>
          {/*end of col*/}
        </div>
        { !noButtons &&
        <div className="row text-center">
          <div className="col">
            {noAmazon &&
                  <a href="#" style={{backgroundColor: "#02B875"}} className="btn btn-secondary m-1" onClick={() => {setNoAmazon(!noAmazon)}}>I don't like Amazon</a>
            }
            {/* {!noAmazon &&
                  <a href="#" className="btn btn-secondary m-1" onClick={() => {setNoAmazon(!noAmazon)}}>I don't like Amazon</a>
            } */}
            {newBooks &&
                  <a href="#" style={{backgroundColor: "#02B875"}} className="btn btn-secondary m-1" onClick={() => {setNewBooks(!newBooks)}}>I only want new books</a>
            }
            {!newBooks &&
                  <a href="#" className="btn btn-secondary m-1" onClick={() => {setNewBooks(!newBooks)}}>I only want new books</a>
            }
          </div>
        </div>
        }
      </>
    );

}

export default SearchBar;