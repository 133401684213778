import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import SearchBar from '../components/SearchBar';
import Books from '../components/Books/Books';
import { getBooks } from '../api';
import Footer from '../components/Footer';
import Logo from '../components/Logo';
import Popover from '@mui/material/Popover';
import Modal from '../components/Modal/Modal'
import '../css/theme.css'
import CountrySelectorBlack from '../components/CountrySelectorBlack/CountrySelectorBlack';

//local storage react hook
const useLocalStorage = (storageKey, fallbackState) => {
    const [value, setValue] = React.useState(
      JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState
    );
  
    React.useEffect(() => {
      localStorage.setItem(storageKey, JSON.stringify(value));
    }, [value, storageKey]);
  
    return [value, setValue];
  };


const BookResults = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, tawkMessengerRef, userCountry, setUserCountry, buttonStatus, setButtonStatus, submitted, setSubmitted, title, setTitle }) => {

    const [bookList, setBookList] = useState([]);
    const [isCardClicked, setCardClicked] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [isSubscribed, setSubscribed] = useLocalStorage('is-subscribed', false)
    const [lastShowCount, setLastShowCount] = useLocalStorage('last-show-count', 1)
    const [originalBookList, setOriginalBookList] = useState([])

    const filter = (books) => {
        if (noAmazon){
            books = books.filter(book => book.Platform !== "abebooks" && book.Platform !== "Amazon")
        }
        if (newBooks){
            books = books.filter(book => book.Condition === "New" || book.Condition === "Brand New" || book.Condition === "Kindle Edition")
        }
        return books
    }
    const sortBooks = (books) => {
        let highestSimilarity = 0;
        let highestOriginal = 0;
        let highestStarting = 0;
        let i = 0;
        books = filter(books)
        // for (i in books) {
        //     if (books[i].similarity < 0.75 && books[i].highestAuthor >= 0.75){
        //         books[i].similarity = books[i].highestAuthor
        //     } else if (books[i].similarity >= 0.75 && books[i].highestAuthor >= 0.75){
        //         books[i].originalSimilarity = 1
        //     }
        //     if (books[i].similarity > highestSimilarity){
        //         highestSimilarity = books[i].similarity;
        //     }
        //     if (books[i].originalSimilarity > highestOriginal){
        //         highestOriginal = books[i].originalSimilarity;
        //     }
        //     if (books[i].startingSimilarity > highestStarting){
        //         highestStarting = books[i].startingSimilarity;
        //     }
        // }
        // books.sort((a, b) => {
        //     if (a.similarity !== b.similarity){
        //         return b.similarity - a.similarity
        //     }
        //     if ((a.startingSimilarity === highestStarting || b.startingSimilarity === highestStarting) && a.startingSimilarity !== b.startingSimilarity){
        //         return b.startingSimilarity - a.startingSimilarity
        //     }
        //     else {
        //         return b.originalSimilarity - a.originalSimilarity
        //     }
        // })
        books.sort((a, b) => {
            if (a.similarity !== b.similarity){
                return b.similarity - a.similarity
            }
        })
        let ebayCount = 0
        for (i in books){
            if (books[i].Platform && books[i].Platform === "ebay"){
                if (ebayCount >= 3){
                    books.splice(i, 1)
                    i--
                }
                ebayCount++
            }
        }
        return books
    }

    useEffect(() => {
        setButtonStatus(false);
        try {
            tawkMessengerRef.current.minimize();
        } catch (error) {
            console.log(error);
        }
        if(title){
            getBooks(title, userCountry, newBooks)
                .then(books => {
                    setBookList(sortBooks(books.results));
                    setOriginalBookList(books.results);
                    setButtonStatus(true);
                })
        } else {
            setButtonStatus(true);
        }
        
    }, [submitted])

    useEffect(() => {
        setBookList(sortBooks(originalBookList))
    }, [newBooks, noAmazon])

    return (
    <>
        {(isCardClicked && !isSubscribed) && 
        <Popover
            open={isCardClicked}
            onClose={() => setCardClicked(false)}
            anchorReference={"none"}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Modal setSubscribed={setSubscribed} isCardClicked={isCardClicked} setCardClicked={setCardClicked}/>
        </Popover>
        }

        <Container>
            <div className="row">
                <div className="mt-3">
                    <Logo size={"width-65"}/>
                </div>
                <div className="mt-3 col">
                    <SearchBar noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle} />
                </div>
            </div>
            <div className="row">
                <Container className="mb-2">
                    <CountrySelectorBlack userCountry={userCountry} setUserCountry={setUserCountry}/>
                </Container>
            </div>
            {!buttonStatus && 
                <Container className="mb-3">
                    <img src='/loading.gif' alt='Loading...'/>
                </Container>
            }
            <small>Some of the below links are affiliate links</small>
            <Books userCountry={userCountry} lastShowCount={lastShowCount} setLastShowCount={setLastShowCount} bookList={bookList} isCardClicked={isCardClicked} setCardClicked={setCardClicked} anchor={anchor} setAnchor={setAnchor}/>
        </Container>
        <Footer/>
    </>
    )

}

export default BookResults;