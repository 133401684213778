import React from "react";

const PageNotFound = () => {
    return (
        <p>
            Page not found - the path your entered did not match
            any Pages in this application.
        </p>
    )

}


export default PageNotFound;
