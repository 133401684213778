import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import ColleenHooverImage from './images/colleen-hoover-image.jpeg';
import ColleenHooverBooksShelf from './images/colleen-hoover-books-shelf.png';
import ItStarts from './images/ItStartsWithUs.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const ItStartsWithUs = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Helmet>
      <title>It Starts With Us</title>
      <meta name="description" content="It Starts With Us - The gripping sequel to Collen Hoover's It Ends with Us" />
    </Helmet>
    <Logo size="width-150" />
    <div>
      <h1 className="page-title">The Emotional Journey Continues: A Look at Colleen Hoover's 'It Starts with Us'</h1>
      <small>By Lucius Small</small>
    </div>

    <img style={{paddingTop: '15px'}} className="image width-750" src={ItStarts} alt="Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">It Started with Atlas</h2>
      <p>Before "It Ends with Us," it started with Atlas. In "It Starts with Us," fans' favorite character, Atlas, returns to share his side of the story. Atlas's story is a unique tale of love, pain, and resilience that articulates the understanding that "It Starts with Us" and it continues with us.</p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">Co-Parenting, New Love, and Complexities</h2>
      <p>In "It Starts with Us," Lily and her ex-husband, Ryle, have established a civil co-parenting rhythm post-separation. However, the re-entry of Atlas into Lily's life adds a new layer of complexity. This book, true to its title, reminds us that every end has a new beginning and that "It Starts with Us" time and again.</p>

    </section>

    <h2>Search for the cheapest place to buy It Starts With Us</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section className="section">
      <h2 className="sub-title">"Navigating Love and Fear</h2>
      <p>Lily's encounter with Atlas introduces the resonating theme: the rekindling of first love. The knowledge that Atlas is the one man Ryle would hate to have in his ex-wife and daughter's life adds a tinge of danger, making "It Starts with Us" a compelling page-turner.</p>

      <h2 style={{paddingTop: '25px'}} className="sub-title">Diverse Perspectives and Character Growth</h2>
      <p>"It Starts with Us" switches between Lily and Atlas's perspectives, enriching the narrative and providing readers with a deeper understanding of their emotions. This switching of perspectives underlines the notion that "It Starts with Us" - it's about shared experiences, understanding, and growth.</p>

    </section>
    <img className="image width-150" src={ColleenHooverImage} alt="Colleen Hoover - It Starts With Us" />


    <section className="section">
      <h2 className="sub-title">Exploring Atlas's Past</h2>
      <p>The book gives fans an intimate glimpse into Atlas's past, which may reshape readers' perceptions of him. This exploration enhances the narrative and emphasizes that "It Starts with Us" - our pasts significantly impact our presents and futures.</p>

      <h2 style={{paddingTop: '25px'}} className="sub-title">Balancing Love and Conflict</h2>
      <p>The balance of "It Starts with Us" lies in its ability to tackle serious issues while celebrating the potential of a second chance at love. As Lily navigates her budding relationship with Atlas amidst the looming presence of her jealous ex-husband, the narrative resonates with authenticity.</p>

    </section>

    <img className="image width-150" src={ColleenHooverBooksShelf} alt="Author Colleen Hoover" />

    <section className="section">
      <h2 className="sub-title">Conclusion: A Thematic Declaration</h2>
      <p>Indeed, with "It Starts with Us," Hoover once again shows her flair for writing emotionally resonant, complex, and engaging narratives. The title isn't merely a reference to the storyline but a thematic declaration. Whether it's about love, forgiveness, moving on, or self-discovery, "It Starts with Us." This book is a powerful reminder that each new chapter in our lives starts with us, making it an absolute must-read for those who crave emotionally rich narratives that resonate long after the last page is turned.</p>

    </section>


    <h2>Search for the cheapest place to buy It Starts With Us</h2>

    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
        <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
    </section>

    <Footer />
  </div>
);

export default ItStartsWithUs;
