import React from "react";

const Footer = () => {

    const year = new Date().getFullYear();

    return (

        <footer className="footer-short">
            <div className="container">
            <hr />
            <nav className="row justify-content-between align-items-center">

                {/*end of col*/}
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <a href="/eula">Terms of Service</a>
                            <span className='m-2'>|</span>
                            <a href="/privacy">Privacy Policy</a>
                        </div>
                    </div>
                </div>
                {/*end of col*/}
            </nav>
            {/*end of row*/}


            <div className="row">
                <div className="col">
                <small>© {year} Singhal Services All Rights Reserved</small>
                </div>
                {/*end of col*/}
            </div>
            {/*end of row*/}
            </div>
            {/*end of container*/}
        </footer>
    );
}

export default Footer;
