import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import ItEndsWithUsTable from './images/it-ends-with-us-table.jpeg';
import ItEndsWithUsBlakeLively from './images/it-ends-with-us-blake-lively.jpeg';
import ItEndsWithUsArt from './images/it-ends-with-us-art.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const ItEndsWithUsSummary = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Helmet>
      <title>It Ends With Us Summary</title>
      <meta name="description" content="A summary of It Ends With Us" />
    </Helmet>
    <Logo size="width-150" />
    <div>
      <h1 className="page-title">It Ends with us Summary - A quick but detailed plot walkthrough</h1>
      <small>By Lucius Small</small>
    </div>

    <img style={{paddingTop: '15px'}} className="image width-750" src={ItEndsWithUsBlakeLively} alt="Blake Lively - It Ends With Us" />

    <section className="section">
      <h2 className="sub-title">Plunge into the Past</h2>
      <p>In the world of contemporary romance, few authors dare to broach the dark crevices of human relationships as boldly as Colleen Hoover. Her 2016 novel "It Ends with Us" is an audacious testament to this. Layered, emotional, and remarkably raw, "It Ends with Us" delves deep into the cycle of domestic abuse, shedding light on the crippling reality of countless silent victims.</p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">The Silent Struggle of Lily Bloom</h2>
      <p>College graduate Lily Bloom, the protagonist in "It Ends with Us," moves to Boston, keen on realizing her dreams of owning a floral shop. The undertones of her challenging past are unveiled through her heartfelt eulogy at her father's funeral, back in her hometown of Plethora, Maine. Lily’s story in "It Ends with Us" exposes the secret anguish of her mother’s abuse at the hands of her father, a reality she was forced to bear witness to during her formative years.</p>

      <p>As she flips through her old childhood diaries, she stumbles upon memories of Atlas Corrigan, her first love who promised to return. Lily's recollection of Atlas, submerged in the pages of "It Ends with Us," leads readers down the intricate pathway of her burgeoning romantic life. Yet, amid this emotional upheaval, she crosses paths with the charismatic neurosurgeon, Ryle Kincaid.</p>

    </section>

    <h2>Search for the cheapest place to buy It Ends With Us</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section className="section">

      <h2 className="sub-title">A Love That Turns Dark</h2>
      <p>The mutual attraction between Lily and Ryle is palpable. However, their contrasting desires for commitment and casual romance present a stumbling block. As Lily carves out her dream business, their paths intersect more frequently, and a relationship eventually ensues. It is at this juncture of "It Ends with Us" that the plot takes a chilling turn, making the title ominously relevant.</p>
    
      <p>Ryle, driven by rage, mirrors the actions of Lily's abusive father, and an incident that was supposed to be a moment of laughter turns into a terrifying echo of Lily's past. The vividly portrayed scene in "It Ends with Us" conveys the disturbing reality of how quickly love can turn into violence. Despite her deep-seated fears, Lily decides to give Ryle another chance, hoping to differentiate their relationship from her parents’.</p>

    </section>
    <img className="image width-150" src={ItEndsWithUsArt} alt="Colleen Hoover - It Ends With Us" />


    <section className="section">
      <h2 className="sub-title">Return of the Past Love</h2>
      <p>The entrance of Atlas into the picture in "It Ends with Us" brings about a tangle of jealousy and suspicion. Ryle's immediate insecurity sets a tense dynamic in motion, making the reader painfully aware of the danger lurking underneath the surface.</p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">Shattering the Illusion of Peace</h2>
      <p>In a shocking development within "It Ends with Us," the domestic tranquility between Lily and Ryle shatters. A succession of violent episodes, secrets, and misplaced blame leads Lily to reconnect with Atlas, sparking a potential lifeline amidst her tumultuous situation. It's this decisive moment in "It Ends with Us" that truly emphasizes the horrifyingly repetitive cycle of abuse.</p>
    
      <h2 style={{paddingTop: '25px'}}className="sub-title">A New Beginning</h2>
      <p>Upon discovering her pregnancy with Ryle's child, Lily makes the brave decision to escape her abusive marriage. The second half of "It Ends with Us" brilliantly showcases Lily's transformation into a resilient survivor, vowing to protect her unborn child from the horrors she experienced. Atlas, despite his unresolved feelings for Lily, becomes her pillar of strength and understanding.</p>
    </section>

    <img className="image width-150" src={ItEndsWithUsTable} alt="Author Colleen Hoover" />

    <section className="section">
      
      <h2 className="sub-title">The Final Stand</h2>
      <p>Ryle’s return in "It Ends with Us" instigates an emotional roller-coaster. He is allowed to coexist in Lily’s life as she navigates through her pregnancy, yet his physical presence is a stark reminder of her tormenting past. Lily's determination to shield her daughter from abuse is finally sealed when she tells Ryle she wants a divorce, reiterating that the vicious cycle of violence ends with them.</p>

      <p>The heartbreaking yet uplifting narrative of "It Ends with Us" serves as a beacon of hope for those caught in the cycle of abuse. Lily's journey to self-discovery and eventual liberation exemplifies the title's profound resonance. The title “It Ends with Us” echoes throughout the book, symbolizing Lily’s personal journey, her fight for a healthier future, and a powerful message to readers about breaking the cycle of abuse.</p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">Hope and Resolution</h2>
      <p>The conclusion of "It Ends with Us" outlines the promising possibility of a better future for Lily and her daughter, free from the trauma of abuse. The epilogue of "It Ends with Us" finds Lily ready to restart her relationship with Atlas, a hopeful resolution that underscores the novel’s title.</p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">The Lasting Echo</h2>
      <p>In every turn of "It Ends with Us," readers are invited to explore the complexities of abuse and the strength it takes to say "enough." The raw narrative woven by Colleen Hoover invites us to reflect on the painful reality of domestic abuse, ultimately leaving us with an unforgettable echo – "It Ends with Us."</p>

      <p>I hope you enjoyed this It Ends With Us summary. If you want to purchase It Ends with us of any Colleen Hoover books, use the search bar below.</p>
    
    </section>


    <h2>Search for the cheapest place to buy It Ends With Us</h2>

    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
        <div className="flex-item">
          <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
    </section>

    <Footer />
  </div>
);

export default ItEndsWithUsSummary;
