import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import PageNotFound from './components/PageNotFound';
import Homescreen from './pages/Homescreen';
import BookResults from './pages/BookResults';
import EULA from './pages/EULA';
import { getVisitorCountry } from './api';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ReactGA from 'react-ga';
import {isMobile} from 'react-device-detect';
import countryMapper from './utils/countryMapper';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ColleenHooverPage from './landings/ColleenHoover';
import ColleenHooverBooks from './landings/CollenHooverBooks';
import Articles from './pages/Articles';
import ItStartsWithUs from './landings/ItStartsWithUs';
import ItEndsWithUs from './landings/ItEndsWithUs';
import ItEndsWithUsSummary from './landings/ItEndsWithUsSummary';
import ItEndsWithUsPdf from './landings/ItEndsWithUsPdf';
import VeritySummary from './landings/VeritySummary';
import ColleenHooverBooksInOrder from './landings/ColleenHooverBooksInOrder';
import JackCarrBooksInOrder from './landings/JackCarrBooksInOrder';
import JackCarrPage from './landings/JackCarr';
import JackCarrBooks from './landings/JackCarrBooks';
import LouisePennyBooksInOrder from './landings/LouisePennyBooksInOrder';
import HarryPotterBooksInOrder from './landings/HarryPotterBooksInOrder';
import BridgertonBooksInOrder from './landings/BridgertonBooksInOrder';
import BridgertonBooks from './landings/BridgertonBooks';
import VerityBook from './landings/VerityBook';
import VerityColleenHoover from './landings/VerityColleenHoover';
import AmazonLinks from './landings/AmazonLinks';
import AdvertiseExtension from './landings/AdvertiseExtension';
import Redirect from './landings/Redirect';
import HackerSentimentPrivacyPolicy from './pages/HackerSentimentPrivacyPolicy';

const TRACKING_ID = "UA-243929212-1";

const useLocalStorage = (storageKey, fallbackState) => {
  const [value, setValue] = React.useState(
    JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState
  );

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

function App() {
  const tawkMessengerRef = useRef();
  const [title, setTitle] = useState("");
  const [submitted, setSubmitted] = useState(0);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [userCountry, setUserCountry] = useLocalStorage('country', "")
  const [tawkMinimized, setTawkMinimized] = useLocalStorage('tawk-minimized', false)
  const [noAmazon, setNoAmazon] = useLocalStorage('noAmazon', false)
  const [newBooks, setNewBooks] = useLocalStorage('newBooks', false)
  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    if (!userCountry){
      getVisitorCountry().then((country) => {
        setUserCountry(countryMapper(country));
      }); 
    }   
  }, [])
  const onBeforeLoad = () => {
    try {
      if (isMobile){
        tawkMessengerRef.current.minimize();
      } else {
        if (!tawkMinimized){
          tawkMessengerRef.current.minimize();
        }
      }
    } catch (error) {
      console.log(error);
    }

  }


  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<Homescreen noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} userCountry={userCountry} setUserCountry={setUserCountry} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} submitted={submitted} setSubmitted={setSubmitted} title={title} setTitle={setTitle}/>}/>
          <Route exact path='/bookresults' element={<BookResults noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} tawkMessengerRef={tawkMessengerRef} userCountry={userCountry} setUserCountry={setUserCountry} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} submitted={submitted} setSubmitted={setSubmitted} title={title} setTitle={setTitle}/>}/>
          <Route exact path='/eula' element={<EULA/>}/>
          <Route exact path='/privacy' element={<PrivacyPolicy/>}/>
          <Route exact path='/productsentimentprivacy' element={<HackerSentimentPrivacyPolicy/>}/>
          <Route exact path='/colleenhoover' element={<ColleenHooverPage noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/colleenhooverbooks' element={<ColleenHooverBooks noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/colleenhooverbooksinorder' element={<ColleenHooverBooksInOrder noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/jackcarrbooksinorder' element={<JackCarrBooksInOrder noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/itstartswithus' element={<ItStartsWithUs noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/itendswithus' element={<ItEndsWithUs noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/itendswithussummary' element={<ItEndsWithUsSummary noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/itendswithuspdf' element={<ItEndsWithUsPdf noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/veritysummary' element={<VeritySummary noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/veritybook' element={<VerityBook noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/veritycolleenhoover' element={<VerityColleenHoover noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/> 
          <Route exact path='/harrypotterbooksinorder' element={<HarryPotterBooksInOrder noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/jackcarr' element={<JackCarrPage noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>}/>
          <Route exact path='/jackcarrbooks' element={<JackCarrBooks noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} submitted={submitted} setSubmitted={setSubmitted} setButtonStatus={setButtonStatus} title={title} setTitle={setTitle}/>}/>
          <Route exact path='/louisepennybooksinorder' element={<LouisePennyBooksInOrder noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} submitted={submitted} setSubmitted={setSubmitted} setButtonStatus={setButtonStatus} title={title} setTitle={setTitle}/>}/>
          <Route exact path='/bridgertonbooksinorder' element={<BridgertonBooksInOrder noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} submitted={submitted} setSubmitted={setSubmitted} setButtonStatus={setButtonStatus} title={title} setTitle={setTitle}/>}/>
          <Route exact path='/bridgertonbooks' element={<BridgertonBooks noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} submitted={submitted} setSubmitted={setSubmitted} setButtonStatus={setButtonStatus} title={title} setTitle={setTitle}/>}/>
          <Route exact path='/amazon' element={<AmazonLinks noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} submitted={submitted} setSubmitted={setSubmitted} setButtonStatus={setButtonStatus} title={title} setTitle={setTitle}/>}/>
          <Route exact path='/extension' element={<AdvertiseExtension noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} submitted={submitted} setSubmitted={setSubmitted} setButtonStatus={setButtonStatus} title={title} setTitle={setTitle}/>}/>
          <Route exact path='/redirect' element={<Redirect />}/>

          <Route exact path='/articles' element={<Articles/>}/>
          <Route path='/404' element={<PageNotFound/>}/>
          <Route path='*' element={<Navigate replace to='/404'/>}/>
        </Routes>
      </Router>
      <TawkMessengerReact
                propertyId="633ba28054f06e12d8984813"
                widgetId="1gegf5ic6"
                onBeforeLoad={onBeforeLoad}
                onChatMinimized={() => setTawkMinimized(true)}
                onChatMaximized={() => setTawkMinimized(false)}
                ref={tawkMessengerRef}
                />
      
    </div>
  );
}

export default App;
