import Book from "./Book/Book";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
const Cards = ({ userCountry, lastShowCount, setLastShowCount, bookList, isCardClicked, setCardClicked, anchor, setAnchor }) => {

      return (
  
        <div className="tab-content">
          <div className="tab-pane fade show active" id="projects" role="tabpanel" aria-labelledby="projects-tab">
            <div className="container">
              <ul className="row feature-list feature-list-sm">
                    <Book userCountry={userCountry} lastShowCount={lastShowCount} setLastShowCount={setLastShowCount} bookList={bookList} isCardClicked={isCardClicked} setCardClicked={setCardClicked} anchor={anchor} setAnchor={setAnchor}/>
              </ul>
              {/*end of row*/}
            </div>
            {/*end of container*/}
          </div>
        </div>
      );
}

export default Cards;