import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import HarryPotterPhilosophers from './images/harry-potter-philosophers.jpeg';
import HarryPotterChamber from './images/harry-potter-chamber.jpeg';
import HarryPotterPrisoner from './images/harry-potter-prisoner.jpg';
import HarryPotterGoblet from './images/harry-potter-goblet.jpeg';
import HarryPotterOrder from './images/harry-potter-order.jpeg';
import HarryPotterHalf from './images/harry-potter-half.jpeg';
import HarryPotterDeathly from './images/harry-potter-deathly.jpeg';
import HarryPotterBooks from './images/harry-potter-books.jpeg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const HarryPotterBooksInOrder = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Logo size="width-150"/>
    <Helmet>
      <title>Harry Potter Books in Order</title>
      <meta name="description" content="Explore the magical world of Harry Potter series in the right order. Dive into the magical journey of Harry and his friends." />
    </Helmet>
    <div>
      <h1 className="article-title">Harry Potter Books in Order: A Magical Journey</h1>
      <small>By Gavin Babji</small>
    </div>
    <img style={{paddingTop: '15px'}} className="image width-750" src={HarryPotterBooks} alt="Harry Potter Books" />

    <section className="section">
      <h2 className="sub-title">The Magical Journey</h2>
      <p>
        The Harry Potter series, authored by J.K. Rowling, takes readers on a magical journey through the life of a young wizard, Harry Potter, and his friends Hermione Granger and Ron Weasley, who all attend the Hogwarts School of Witchcraft and Wizardry.
      </p>

      <ul>
        <li>"Harry Potter and the Philosopher's Stone" (1997)</li>
        <li>"Harry Potter and the Chamber of Secrets" (1998)</li>
        <li>"Harry Potter and the Prisoner of Azkaban" (1999)</li>
        <li>"Harry Potter and the Goblet of Fire" (2000)</li>
        <li>"Harry Potter and the Order of the Phoenix" (2003)</li>
        <li>"Harry Potter and the Half-Blood Prince" (2005)</li>
        <li>"Harry Potter and the Deathly Hallows" (2007)</li>
      </ul>
    </section>

    <h2>Search for the cheapest place to buy Harry Potter books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <img style={{paddingTop: '15px'}} className="book-card" src={HarryPotterPhilosophers} alt="Harry Potter Books" />

    <section className="section">
      <h2 className="sub-title">"Harry Potter and the Philosopher's Stone" (1997)</h2>
      <p>
      The book introduces Harry Potter, a young orphan who discovers on his eleventh birthday that he is a wizard and has been accepted into Hogwarts School of Witchcraft and Wizardry. Harry learns about his parents, who were renowned wizards, and their fate. He uncovers his own fame in the wizarding world, due to his survival from an attack by the dark wizard, Voldemort. Harry forms friendships with Hermione Granger and Ron Weasley, and together they prevent Voldemort from acquiring the Philosopher’s Stone and achieving immortality.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={HarryPotterChamber} alt="Harry Potter Books" />

    <section className="section">
      <h2 className="sub-title">"Harry Potter and the Chamber of Secrets" (1998)</h2>
      <p>
      In this book, Harry, now in his second year at Hogwarts, hears strange voices and students start to get petrified. He learns about the Chamber of Secrets and its beast, the Basilisk. Harry finds out he can speak Parseltongue (snake language), which makes him a suspect. Eventually, with the help of his friends, Harry discovers that the real culprit is a memory of Tom Riddle (Voldemort's real name) from a diary, who had controlled Ginny Weasley to reopen the Chamber. Harry battles the Basilisk and destroys the diary, a piece of Voldemort’s soul, unknowingly destroying a Horcrux.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={HarryPotterPrisoner} alt="Harry Potter Books" />

    <section className="section">
      <h2 className="sub-title">"Harry Potter and the Prisoner of Azkaban" (1999)</h2>
      <p>
      Harry learns that Sirius Black, a notorious supporter of Voldemort, has escaped from the wizard prison, Azkaban, and intends to kill him. Throughout the year, Harry learns about his father’s friendship with Sirius and Remus Lupin, and he learns more about his parents' death. He discovers that Sirius was framed by their other friend, Peter Pettigrew, who is actually the one who betrayed Harry’s parents to Voldemort. The climax involves time travel, Pettigrew's escape, and Sirius' evasion of the Dementors, the soul-sucking guards of Azkaban.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={HarryPotterGoblet} alt="Harry Potter Books" />

    <section className="section">
      <h2 className="sub-title">"Harry Potter and the Goblet of Fire" (2000)</h2>
      <p>
      This book revolves around the Triwizard Tournament held at Hogwarts, in which Harry is forced to compete. The tournament is a cover for a plot by Voldemort and one of his followers (disguised as a professor at Hogwarts) to bring Harry to Voldemort, who regains his body and returns to power. In the final task, Harry and fellow student Cedric Diggory touch the Triwizard Cup, which has been turned into a Portkey, and are transported to a graveyard where Harry witnesses Voldemort's resurrection. Cedric is killed, and Harry barely escapes, bringing Cedric's body back with him.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={HarryPotterOrder} alt="Harry Potter Books" />

    <section className="section">
      <h2 className="sub-title">"Harry Potter and the Order of the Phoenix" (2003)</h2>
      <p>
      Harry is now in his fifth year at Hogwarts, and Voldemort is back but the wizarding world is in denial. Harry and his friends face political suppression from the Ministry of Magic, which refuses to acknowledge Voldemort's return, even as it takes control of Hogwarts. Harry and his friends form a secret student group, Dumbledore’s Army, to teach defensive magic. Harry also has dreams connected to Voldemort, and through one of these is manipulated into leading his friends to the Ministry, resulting in the death of his godfather, Sirius Black. This leads to a battle between the Order of the Phoenix and Voldemort's followers, the Death Eaters, and the Ministry finally acknowledges Voldemort's return.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={HarryPotterHalf} alt="Harry Potter Books" />

    <section className="section">
      <h2 className="sub-title">"Harry Potter and the Half-Blood Prince" (2005)</h2>
      <p>
      As Voldemort and his followers openly wreak havoc, Harry and Dumbledore delve into the past of Voldemort's alter ego, Tom Riddle, through memories. Dumbledore and Harry retrieve a supposed Horcrux, but it is a fake. They return to Hogwarts to find the school under attack. During the battle, Dumbledore is killed by Professor Snape, and Harry learns that he must hunt down and destroy the remaining Horcruxes to finally defeat Voldemort.
      </p>
    </section>

    <img style={{paddingTop: '15px'}} className="book-card" src={HarryPotterDeathly} alt="Harry Potter Books" />

    <section className="section">
      <h2 className="sub-title">"Harry Potter and the Deathly Hallows" (2007)</h2>
      <p>
      The final book involves Harry, Hermione, and Ron leaving Hogwarts to complete Dumbledore's mission to destroy the remaining Horcruxes. The wizarding world is under Voldemort's control, and Harry is now its most wanted criminal. Harry learns about the fabled Deathly Hallows, and comes to possess them. Many battles occur, culminating in the Battle of Hogwarts. Several characters die, but Harry, with the help of his friends, is able to destroy all the Horcruxes and finally defeat Voldemort. The epilogue shows Harry, Hermione, and Ron sending their own children off to Hogwarts, hinting at a return to peace in the wizarding world.
      </p>
    </section>

    <section className="section">
      <h2 className="sub-title">In Conclusion</h2>
      <p>
        The Harry Potter series is a must-read for any fan of fantasy and adventure. The enchanting narrative, the character development, and the intricate plot make it an unforgettable reading experience. Whether you are a new reader or revisiting the series, following the books in order will lead you through the magical journey crafted by J.K. Rowling.
      </p>
    </section>

    <h2>Search for the cheapest place to buy Harry Potter books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
      <div className="flex-item">
        <a href="/colleenhoover">
            <span>Colleen Hoover</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/colleenhooverbooks">
            <span>Colleen Hoover Books</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/itstartswithus">
            <span>It Starts With Us</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/itendswithus">
            <span>It Ends With Us</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/veritysummary">
            <span>Verity Summary</span>
        </a>
        </div>
        <div className="flex-item">
        <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
        </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarr">
                <span>Jack Carr</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
    </section>

  <Footer />
  </div>
);

export default HarryPotterBooksInOrder;
