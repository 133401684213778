export default function countryMapper (country){
  if (country === "AU" || country ==="NZ"){
    return "AU"
  }
  else if (country === "UK" || country === "PL" || country === "IE" || country === "GB"){
    return "UK"
  }
  else{
    return "US"
  }
}