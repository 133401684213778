import React from 'react'
import Logo from '../components/Logo';
import Footer from '../components/Footer';

function EULA() {
  return (
    <>
      <Logo size={'width-150'} />
      <div style={{textAlign: 'left', padding: 20}}>
        <h1>Pages on Pages - End User License Agreement (EULA)</h1>
        <h2>1. Agreement Acceptance</h2>
        <p>By using the Pages on Pages website, service, or Chrome extension, you agree to abide by the terms and conditions laid out in this End User License Agreement (EULA). This EULA is a legal agreement between you and Singhal Services, the owner and operator of Pages on Pages.</p>
        <h2>2. Services</h2>
        <p>Pages on Pages allows users to search for books, compiles and provides information on the book prices from various sellers online in the users' local area. Our service utilizes web scraping techniques to gather this information. We also offer a Chrome extension that displays cheaper options for books searched by the user.</p>
        <h2>3. Affiliation and Financial Disclosure</h2>
        <p>Pages on Pages uses affiliate links to external bookstores. If a user clicks on these links and makes a purchase, we may receive a commission. This does not influence the price the user pays for the book.</p>
        <h2>4. Eligibility</h2>
        <p>You affirm that you are at least 18 years old and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in this EULA.</p>
        <h2>5. License Grant</h2>
        <p>Singhal Services grants you a non-exclusive, non-transferable, revocable, and limited license to use our service. You may not reproduce, distribute, publicly display, or publicly perform any part of our services.</p>
        <h2>6. Data Usage and Privacy</h2>
        <p>By using our service, you agree to the collection and use of your data in accordance with our Privacy Policy. This includes the use of cookies and similar technologies for the purposes of providing, improving, and personalizing our service.</p>
        <h2>7. Third-Party Sites</h2>
        <p>Our service contains links to third-party websites that are not owned or controlled by Singhal Services. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites.</p>
        <h2>8. Liability and Warranties</h2>
        <p>Pages on Pages and all its services are provided "as is" without warranty of any kind. Singhal Services does not guarantee the accuracy, reliability, or completeness of the content provided by our services. We shall not be held liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our service.</p>
        <h2>9. Termination</h2>
        <p>We reserve the right to terminate or suspend your access to our services, without prior notice or liability, for any reason, including without limitation if you breach the terms of this EULA.</p>
        <h2>10. Governing Law</h2>
        <p>This EULA will be governed by the laws of Australia without regard to its conflict of law provisions.</p>
        <h2>11. Changes to the EULA</h2>
        <p>We reserve the right to amend this EULA at any time. Your continued use of Pages on Pages after any such changes constitutes your acceptance of the new terms.</p>
        <h2>12. Contact Information</h2>
        <p>If you have any questions about this EULA, please contact us at creasyspines@gmail.com.</p>
        <p><i>Last updated: 20th June 2023</i></p>
      </div>
      <Footer />
    </>
  )
}

export default EULA;