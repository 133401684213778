import React from 'react';
import SearchBar from '../components/SearchBar';
import './css/LandingPage.css';
import JackCarrWithFan from './images/jack-carr-image.jpg';
import JackCarrJoeRogan from './images/jack-carr-joe-rogan.jpg';
import JackCarrBook from './images/Jack-Carr.jpg';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const JackCarrPage = ({ noAmazon, setNoAmazon, newBooks, setNewBooks, buttonStatus, setButtonStatus, title, submitted, setSubmitted, setTitle }) => (
  <div className="article">
    <Helmet>
      <title>Jack Carr</title>
      <meta name="description" content="Former Navy SEAL turned author, Jack Carr brings authenticity and realism to his thrilling James Reece series. His writing deeply explores the cost of warfare on individuals and society." />
    </Helmet>
    <Logo size="width-150" />
    <div>
      <h1 className="page-title">Jack Carr - Realism in Thrillers</h1>
      <small>By Lucius Small</small>
    </div>

    <img style={{paddingTop: '15px'}} className="image width-750" src={JackCarrBook} alt="Jack Carr" />

    <section className="section">
      <h2 className="sub-title">The Journey Begins</h2>
      <p>
        Born and raised in a military family, Jack Carr knew early on that he wanted to serve his country. After the terror attacks on September 11, 2001, he decided to join the Navy and became a SEAL, serving for 20 years and participating in numerous operations across the globe.
      </p>

      <p>
        Upon retiring from military service, Carr found another way to serve - by writing. His debut novel, "The Terminal List," introduced readers to James Reece, a Navy SEAL who seeks revenge after his team is ambushed during a covert operation.
      </p>

      <h2 style={{paddingTop: '25px'}}className="sub-title">A Master Storyteller</h2>
      <p>
        Drawing on his military background, Carr crafts thrilling stories filled with intricate details, realism, and suspense. His writing explores the toll of war on individuals and society, a theme prevalent in all his books including "True Believer" and "Savage Son."
      </p>
    </section>

    <h2>Search for the cheapest place to buy Jack Carr books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section className="section">
      <h2 className="sub-title">James Reece: The Modern Warrior</h2>
      <p>
        Jack Carr's protagonist, James Reece, is a complex character who navigates the murky waters of vengeance, justice, and morality. Carr explores how Reece's experiences on and off the battlefield shape his worldview, making for an engaging and thought-provoking reading experience.
      </p>

      <p>
        Readers have praised Carr's ability to build tension and deliver unexpected twists, while also exploring deeper themes like the cost of vengeance and the struggle for redemption.
      </p>

      <h2 style={{paddingTop: '25px'}} className="sub-title">Success and Future Plans</h2>
      <p>
        Jack Carr's novels have been well received, and he has been compared to thriller genre greats like Brad Thor and Vince Flynn. His success has led to a series deal with Amazon Prime, bringing the character of James Reece to life on screen.
      </p>
    </section>
    <img className="image width-200" src={JackCarrWithFan} alt="Author Jack Carr" />

    <section className="section">
      <h2 className="sub-title">A Legacy in Progress</h2>
      <p>
        Even with the success he's found, Jack Carr remains dedicated to his craft and continues to write. With more James Reece novels on the way, Carr's legacy in the thriller genre seems destined to grow.
      </p>
      <p>
        Jack Carr has used his unique background and experiences to create a new and authentic voice in the thriller genre, captivating readers with his realism, attention to detail, and thoughtful exploration of complex themes.
      </p>
    </section>

    <img className="image width-150" src={JackCarrJoeRogan} alt="Author Jack Carr" />

    <section className="section">
      <h2 className="sub-title">A Final Word</h2>
      <p>
        Jack Carr has proven that the world of thriller novels has room for authenticity and realism. His readers look forward to every release, eager for another deep dive into the gritty, intense world of James Reece.
      </p>
      <p>
        It's safe to say that Jack Carr has successfully transitioned from the battlefield to the writing desk, crafting gripping tales that have resonated with readers worldwide.
      </p>
    </section>

    <h2>Search for the cheapest place to buy Jack Carr books</h2>
    <SearchBar noButtons={true} noAmazon={noAmazon} setNoAmazon={setNoAmazon} newBooks={newBooks} setNewBooks={setNewBooks} buttonStatus={buttonStatus} setButtonStatus={setButtonStatus} title={title} submitted={submitted} setSubmitted={setSubmitted} setTitle={setTitle}/>

    <section>
      <h2 className="sub-title">See more like this</h2>
      <div className="flex">
        <div className="flex-item">
          <a href="/colleenhoover">
            <span>Colleen Hoover</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/jackcarrbooksinorder">
            <span>Jack Carr Books In Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itstartswithus">
            <span>It Starts With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithus">
            <span>It Ends With Us</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithussummary">
            <span>It Ends With Us Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/itendswithuspdf">
            <span>It Ends With Us PDF</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/colleenhooverbooksinorder">
            <span>Colleen Hoover Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/veritysummary">
            <span>Verity Summary</span>
          </a>
        </div>
        <div className="flex-item">
          <a href="/harrypotterbooksinorder">
            <span>Harry Potter Books in Order</span>
          </a>
        </div>
        <div className="flex-item">
            <a href="/jackcarrbooks">
                <span>Jack Carr Books</span>
            </a>
        </div>
        <div className="flex-item">
            <a href="/louisepennybooksinorder">
                <span>Louise Penny Books In Order</span>
            </a>
        </div>
      </div>
      
    </section>

    <Footer />
  </div>
);

export default JackCarrPage;
