import React from 'react'
import { Select, MenuItem } from '@mui/material';


function CountrySelectorBlack({ userCountry, setUserCountry }) {


  const selectCountryHandler = (country) => {
    setUserCountry(country);
  }

  return (
    <Select
      style={{
        float: 'right',
      }}
      value={userCountry}
      onChange={(e) => selectCountryHandler(e.target.value)}
      >
        <MenuItem key="US" value="US">USA</MenuItem>
        <MenuItem key="UK" value="UK">United Kingdom</MenuItem>
        <MenuItem key="AU" value="AU">Australia</MenuItem>
    </Select>
  )
}


export default CountrySelectorBlack;